import { useState } from "react";

import Error from "core/components/Error";
import ConfirmModal from "core/components/ConfirmModal";
import useToaster from "core/hooks/useToaster";
import Typography from "core/components/Typography";
import { downloadJson } from "./util";
import { useParams } from "react-router-dom";

const DownloadNewCredentialModal = ({
  title,
  content,
  credSettings,
  close,
}) => {
  const { toaster } = useToaster();
  const params = useParams();
  const { email, orgId: organizationId } = params;

  const [error, setError] = useState(null);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);

  const { generateCredential, toasterMessage, getFileName, onMutationSuccess } =
    credSettings;

  const onNo = () => {
    setError(null);
    close();
  };

  const onYes = async () => {
    setError(null);
    setIsDownloadInProgress(true);

    try {
      const result = await generateCredential({ email, organizationId });
      const credential = result.credential;
      const fileName = getFileName(credential);

      downloadJson(credential, `credential-${fileName}`);
      setIsDownloadInProgress(false);

      onMutationSuccess && onMutationSuccess();

      toaster.success({
        message: toasterMessage,
      });

      close();
    } catch (error) {
      console.error(error);
      setError(error);
      setIsDownloadInProgress(false);
    }
  };

  return (
    <ConfirmModal
      visible={true}
      onNo={onNo}
      noLabel="Cancel"
      onYes={onYes}
      isSubmitting={isDownloadInProgress}
      dismiss={onNo}
      title={title}
    >
      <Error>{error}</Error>
      <Typography variant="p" style={{ maxWidth: "500px" }}>
        {content}
      </Typography>
    </ConfirmModal>
  );
};

export default DownloadNewCredentialModal;
