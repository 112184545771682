import { splitFormProps, useField } from "react-form";
import Skeleton from "react-loading-skeleton";
import ReactSelect, { components } from "react-select";
import styled from "styled-components";

import {
  FieldError,
  InputGroup,
  InputSkeletonContainer,
  Label,
} from "./styles";

const styleOverrides = (incomingStyles) => ({
  control: (baseStyles, state) => {
    const globalStyles = state.menuIsOpen
      ? {
          ...baseStyles,

          borderColor: "#015fcc",

          backgroundColor: state.isDisabled
            ? "#E4E4E4"
            : baseStyles.backgroundColor,

          "&:hover": {
            borderColor: "#015fcc",
          },
        }
      : {
          ...baseStyles,
          backgroundColor: state.isDisabled
            ? "#E4E4E4"
            : baseStyles.backgroundColor,
          "&:hover": {
            borderColor: "#ddbda",
          },
        };

    return incomingStyles.control
      ? incomingStyles.control(globalStyles, state)
      : globalStyles;
  },
  singleValue: (baseStyles, state) => {
    return {
      ...baseStyles,
      color: state.isDisabled ? "#3E3E3C" : "",
    };
  },
  // Class for this is called "indicatorContainer"
  dropdownIndicator: (baseStyles, state) => {
    const globalStyles = {
      ...baseStyles,
      color: state.isDisabled ? "#706E6B" : baseStyles.color,
    };

    return incomingStyles.control
      ? incomingStyles.control(globalStyles, state)
      : globalStyles;
  },
  indicatorSeparator: (baseStyles, state) => {
    return {
      ...baseStyles,
      display: "none",
    };
  },
  option: (baseStyles, state) => {
    return {
      ...baseStyles,
      color: "#080707",
      cursor: "pointer",
      backgroundColor: state.isSelected
        ? "#c7d8f0"
        : state.isFocused
        ? "#F5F2FC"
        : "#fff",
      ":hover": {
        backgroundColor: state.isSelected ? "#c7d8f0" : "#F5F2FC",
      },
    };
  },
});

export const StyledMenu = styled(components.Menu)``;

const CustomStyledMenu = (props) => {
  return (
    <StyledMenu
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        "data-cy": "react-select-menu-testid",
      })}
    >
      {props.children}
    </StyledMenu>
  );
};

export const StyledSelectContainer = styled(components.SelectContainer)``;

const CustomStyledSelectContainer = (props) => {
  return (
    <StyledSelectContainer
      {...props}
      innerProps={Object.assign({}, props.selectProps, {
        "data-cy": props.selectProps.dataCy,
      })}
    >
      {props.children}
    </StyledSelectContainer>
  );
};

export const FormSelect = (props) => {
  const [field, fieldOptions, rest] = splitFormProps(props);
  const {
    label,
    placeholder,
    options,
    isSearchable = false,
    isLoading,
    styles = {},
    autoFocus = false,
    disabled = false,
  } = rest;
  const {
    value = "",
    setValue,
    meta: { error, isTouched },
  } = useField(field, {
    ...fieldOptions,
  });

  if (isLoading) {
    return (
      <InputGroup>
        <Label>{label}</Label>
        <InputSkeletonContainer>
          <Skeleton />
        </InputSkeletonContainer>
      </InputGroup>
    );
  }

  const handleSelectChange = (newOption) => {
    setValue(newOption.value);
  };

  return (
    <InputGroup>
      <Label htmlFor={field}>{label}</Label>
      <ReactSelect
        classNamePrefix="react-select"
        inputId={field}
        options={options}
        onChange={handleSelectChange}
        value={options.find((option) => option.value === value)}
        autoFocus={autoFocus}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isDisabled={disabled}
        styles={{ ...styles, ...styleOverrides(styles) }}
        components={{
          Menu: CustomStyledMenu,
          SelectContainer: CustomStyledSelectContainer,
        }}
      />
      <FieldError>{error && isTouched ? error : ""}</FieldError>
    </InputGroup>
  );
};

export const Select = ({
  field,
  name,
  label,
  placeholder,
  options,
  isSearchable = false,
  isLoading,
  value,
  onChange,
  autoFocus = false,
  error,
  isTouched = false,
  styles,
  hideError = false,
}) => {
  if (isLoading) {
    return (
      <InputGroup>
        <Label>{label}</Label>
        <InputSkeletonContainer>
          <Skeleton />
        </InputSkeletonContainer>
      </InputGroup>
    );
  }

  const handleSelectChange = (newOption) => {
    onChange(newOption.value);
  };

  return (
    <InputGroup>
      <Label htmlFor={field}>{label}</Label>
      <ReactSelect
        inputId={field}
        classNamePrefix="react-select"
        name={name}
        options={options}
        onChange={handleSelectChange}
        value={options.find((option) => option.value === value)}
        autoFocus={autoFocus}
        placeholder={placeholder}
        isSearchable={isSearchable}
        styles={{ ...styles, ...styleOverrides(styles) }}
        dataCy={name}
        components={{
          Menu: CustomStyledMenu,
          SelectContainer: CustomStyledSelectContainer,
        }}
      />
      {!hideError && <FieldError>{error && isTouched ? error : ""}</FieldError>}
    </InputGroup>
  );
};
