import UserForm from "modules/admin/UserForm";
import { createUser } from "modules/admin/actions";
import useToaster from "core/hooks/useToaster";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import { useMemo, useEffect } from "react";
import CloseButton from "core/components/CloseButton";
import Typography from "core/components/Typography";
import { Grid, GridColumn } from "core/styles";

const CreateUser = () => {
  const { toaster } = useToaster();
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { orgId: organizationId } = params;
  const apiCall = useAuthenticatedMutation((user) =>
    createUser({
      ...user,
      alternateEmail: user.alternateEmail || null,
      organizationId,
    })
  );

  const defaultValues = useMemo(
    () => ({
      name: "",
      email: "",
      role: "",
      alternateEmail: "",
      tableauConfig: { tableauLicense: "" },
      direct_data_access_enabled: false,
    }),
    []
  );

  const mutation = useMutation({
    mutationFn: apiCall,
    onSuccess: async (data, newUser, context) => {
      // Add user to cached query results
      queryClient.setQueryData(["users", organizationId], (cachedList) =>
        [
          {
            isUpdated: true,
            organizationId,
            ...newUser,
          },
        ].concat(cachedList || [])
      );

      // Invalidate cached query results
      queryClient.invalidateQueries({ queryKey: ["users", organizationId] });

      // Notify and navigate
      toaster.success({
        message: "User has been created.",
        duration: 750,
        onDismiss: () => {
          // Back to users table
          navigate(`/admin/org-management/${organizationId}`);
        },
      });
    },
  });

  useEffect(() => {
    window.history.pushState({ from: "Users" }, "");
  }, []);

  return (
    <div>
      <CloseButton
        onClose={() => navigate(`/admin/org-management/${organizationId}`)}
      />
      <Grid
        columns="350px"
        justify="center"
        lift="15vh"
        style={{ marginTop: "120px" }}
      >
        <GridColumn>
          <Typography variant="h2">Create User</Typography>
          <UserForm
            onSubmit={mutation.mutateAsync}
            onCancel={() => navigate(`/admin/org-management/${organizationId}`)}
            defaultValues={defaultValues}
            error={mutation.error}
            isSubmitting={mutation.isPending}
          />
        </GridColumn>
      </Grid>
    </div>
  );
};

export default CreateUser;
