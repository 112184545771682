import { useEffect, useMemo, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { DateTime } from "luxon";
import z from "zod";
import DocumentTitle from "react-document-title";

import Link from "core/components/Link";
import { updatePassword } from "modules/auth/actions";
import useToaster from "core/hooks/useToaster";
import useCurrentUser from "core/hooks/useCurrentUser";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import UnifiedAuthPageWrapper from "core/components/UnifiedAuthPageWrapper";
import { Fieldset } from "core/components/Form/styles";
import { newPasswordZodValidator } from "common/validation";
import { PERMISSIONS, ROLES } from "core/constants";
import { DURATION_30_MIN } from "common/utilities";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { datadogRum } from "@datadog/browser-rum";
import Loader from "core/components/Loader";

const formSchema = z
  .object({
    oldPassword: z.string().min(1),
    newPassword: z.string().superRefine(newPasswordZodValidator),
    newPasswordConfirm: z.string(),
    usernameParts: z.array(z.string()),
  })
  .superRefine((data, context) => {
    if (data.newPassword === data.oldPassword) {
      context.addIssue({
        message: "Passwords must be a new password.",
        path: ["newPassword"],
      });
    }

    const passwordContainsUsername = data.usernameParts.some((usernamePart) => {
      return data.newPassword.toLowerCase().includes(usernamePart);
    });
    if (passwordContainsUsername) {
      context.addIssue({
        message: "Must not contain your username in any form.",
        path: ["newPassword"],
      });
    }

    if (data.newPassword !== data.newPasswordConfirm) {
      context.addIssue({
        message: "Passwords must match.",
        path: ["newPasswordConfirm"],
      });
    }
  });

const ExpiringPassword = () => {
  const auth = useAuth();
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { toaster } = useToaster();

  const authStartedLoading = useRef(DateTime.now());
  const [isPageLoading, setIsPageLoading] = useState(auth.isLoading);

  console.log(auth.activeNavigator);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.activeNavigator &&
      auth.isAuthenticated &&
      !auth.isLoading &&
      (!currentUser || currentUser?.expiresAt !== auth.user.expires_at)
    ) {
      window.auth = auth;

      const now = Date.now();
      const passwordMeta = auth.user.profile.password_meta;
      const passwordLastUpdated = DateTime.fromMillis(passwordMeta.lastUpdated);
      const daysSincePasswordLastUpdated = DateTime.now()
        .diff(passwordLastUpdated, "days")
        .toObject().days;
      const daysRemaining =
        passwordMeta.expirationPeriod - daysSincePasswordLastUpdated;
      const isPasswordExpiring =
        daysRemaining <= passwordMeta.updateReminderPeriod;

      const loggedInUser = {
        email: auth.user.profile.email,
        name: auth.user.profile.name,
        organizationId: auth.user.profile.org_info?.id,
        orgName: auth.user.profile.org_info?.name,
        accessToken: auth.user.access_token,
        refreshToken: auth.user.refresh_token,
        role: ROLES[auth.user.profile.role] || "Org User",
        permissions: PERMISSIONS[ROLES[auth.user.profile.role]] || [],
        expirationTime: now + DURATION_30_MIN,
        expiresAt: auth.user.expires_at,
        lastActiveTime: now,
        passwordMeta,
        isPasswordExpiring,
      };

      setCurrentUser(loggedInUser);
      const loadTime = DateTime.now()
        .diff(authStartedLoading.current, "seconds")
        .toObject().seconds;

      setTimeout(() => {
        setIsPageLoading(false);
      }, 500 - loadTime);

      const identifiedUserSession = datadogRum.getUser();
      if (!identifiedUserSession?.email) {
        datadogRum.setUser({
          name: loggedInUser.name,
          email: loggedInUser.email,
          role: loggedInUser.role,
          organizationId: loggedInUser.organizationId,
        });
      }
    }
  }, [auth, currentUser, setCurrentUser]);

  const usernameParts = useMemo(() => {
    const lowerEmail = currentUser?.email.split("@")[0].toLowerCase();

    if (lowerEmail) {
      const emailSplitIntoFourCharacters = lowerEmail.split("");

      return emailSplitIntoFourCharacters.reduce(
        (finalList, currentValue, currentIndex, originalArray) => {
          if (currentIndex > originalArray.length - 4) {
            return finalList;
          }

          return finalList.concat(
            originalArray.slice(currentIndex, currentIndex + 4).join("")
          );
        },
        []
      );
    }
  }, [currentUser?.email]);

  const [fields, setFields] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });
  const [fieldErrors, setFieldErrors] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFields((previousValues) => {
      const updatedFormValues = {
        ...previousValues,
        [name]: value,
      };

      const validatedForm = formSchema.safeParse({
        ...updatedFormValues,
        usernameParts,
      });

      if (validatedForm.success) {
        setFieldErrors({
          oldPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        });
      } else {
        const flattenedFieldErrors = validatedForm.error.flatten((issue) => {
          return issue.message;
        }).fieldErrors;

        const fieldErrorKeys = Object.keys(fieldErrors);
        const updatedErrors = fieldErrorKeys.reduce(
          (errorMap, fieldErrorKey) => {
            // Don't set error unless field has a value (isTouched behavior)
            const isFieldTouched = !!updatedFormValues[fieldErrorKey];

            // If field has error, pluck first error off of issue list, or clear error
            errorMap[fieldErrorKey] =
              flattenedFieldErrors[fieldErrorKey] && isFieldTouched
                ? flattenedFieldErrors[fieldErrorKey][0]
                : "";
            return errorMap;
          },
          {}
        );

        setFieldErrors(updatedErrors);
      }

      return updatedFormValues;
    });
  };

  const daysRemaining = useMemo(() => {
    if (!currentUser) {
      return 0;
    }

    const passwordMeta = currentUser.passwordMeta;
    const passwordLastUpdated = DateTime.fromMillis(passwordMeta.lastUpdated);
    const daysSincePasswordLastUpdated = Math.floor(
      DateTime.now().diff(passwordLastUpdated, "days").toObject().days
    );

    return (
      currentUser.passwordMeta.expirationPeriod - daysSincePasswordLastUpdated
    );
  }, [currentUser]);

  const apiUpdatePassword = useAuthenticatedMutation(updatePassword);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormError("");
    setIsLoading(true);

    try {
      await apiUpdatePassword(fields);
      toaster.success({
        title: "Success",
        message: "Password has been updated successfully.",
      });

      setTimeout(() => {
        auth.signinRedirect({ prompt: "login" });
      }, 1500);
    } catch (error) {
      console.error(error);
      setFormError(error);
      setIsLoading(false);
    }
  };

  const dismiss = () => {
    setCurrentUser((user) => ({ ...user, isPasswordExpiring: false }));
  };

  const isFormValid = useMemo(() => {
    return formSchema.safeParse({
      ...fields,
      usernameParts,
    }).success;
  }, [fields, usernameParts]);

  if (currentUser && !currentUser.isPasswordExpiring) {
    return <Navigate to="/" replace />;
  }

  if (auth.isLoading || !currentUser || isPageLoading) {
    return <Loader style={{ paddingBottom: "20vh" }} />;
  }

  return (
    <UnifiedAuthPageWrapper>
      <DocumentTitle title={"Populi | Update Password"} />
      <div className="form-wrapper">
        <div className="main-header">
          <h1 className="main-header__heading">Update password</h1>
          <p>Password must be least 15 characters in length.</p>
        </div>
        <div
          className="application-message__text"
          style={{ marginBottom: "15px", marginTop: "-15px" }}
        >
          <p>
            Your password is set to expire in{" "}
            <b>
              {daysRemaining} day
              {daysRemaining !== 1 ? "s" : ""}
            </b>
            .
          </p>
        </div>

        <form id="login-form" onSubmit={handleSubmit}>
          <Fieldset disabled={isLoading}>
            <div className="form-section">
              <div className="input__header">
                <label htmlFor="oldPassword" className="input__label">
                  Old Password
                </label>

                {fieldErrors.oldPassword && (
                  <span id="password-error" className="input-error is-errored">
                    {fieldErrors.oldPassword}
                  </span>
                )}
              </div>
              <div className="password">
                <input
                  className="password__input"
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  placeholder="Enter old password"
                  autoComplete="off"
                  required
                  onChange={handleInputChange}
                  value={fields.oldPassword}
                  autoFocus
                />

                <button
                  type="button"
                  id="toggle-password"
                  className="password__toggle"
                  tabIndex="-1"
                  onClick={(event) => {
                    const field = event.target.form.oldPassword;
                    const fieldType = field.type;
                    const showToggle = event.target;

                    if (fieldType === "password") {
                      showToggle.textContent = "hide";
                      field.type = "text";
                    } else {
                      showToggle.textContent = "show";
                      field.type = "password";
                    }
                  }}
                >
                  Show
                </button>
              </div>
            </div>
            <div className="form-section">
              <div className="input__header">
                <label htmlFor="newPassword" className="input__label">
                  New Password
                </label>

                {fieldErrors.newPassword && (
                  <span id="password-error" className="input-error is-errored">
                    {fieldErrors.newPassword}
                  </span>
                )}
              </div>
              <div className="password">
                <input
                  className="password__input"
                  id="password"
                  name="newPassword"
                  type="password"
                  placeholder="Enter new password"
                  autoComplete="new-password"
                  required
                  onChange={handleInputChange}
                  value={fields.newPassword}
                />

                <button
                  type="button"
                  id="toggle-password"
                  className="password__toggle"
                  tabIndex="-1"
                  onClick={(event) => {
                    const field = event.target.form.newPassword;
                    const fieldType = field.type;
                    const showToggle = event.target;

                    if (fieldType === "password") {
                      showToggle.textContent = "hide";
                      field.type = "text";
                    } else {
                      showToggle.textContent = "show";
                      field.type = "password";
                    }
                  }}
                >
                  Show
                </button>
              </div>
            </div>
            <div className="form-section">
              <div className="input__header">
                <label htmlFor="newPasswordConfirm" className="input__label">
                  Confirm Password
                </label>

                {fieldErrors.newPasswordConfirm && (
                  <span
                    id="confirmPassword-error"
                    className="input-error is-errored"
                  >
                    {fieldErrors.newPasswordConfirm}
                  </span>
                )}
              </div>
              <div className="password">
                <input
                  className="password__input"
                  id="newPasswordConfirm"
                  name="newPasswordConfirm"
                  type="password"
                  placeholder="Confirm new password"
                  autoComplete="new-password"
                  required
                  onChange={handleInputChange}
                  value={fields.newPasswordConfirm}
                />

                <button
                  type="button"
                  id="toggle-confirmPassword"
                  className="password__toggle"
                  tabIndex="-1"
                  onClick={(event) => {
                    const field = event.target.form.newPasswordConfirm;
                    const fieldType = field.type;
                    const showToggle = event.target;

                    if (fieldType === "password") {
                      showToggle.textContent = "hide";
                      field.type = "text";
                    } else {
                      showToggle.textContent = "show";
                      field.type = "password";
                    }
                  }}
                >
                  Show
                </button>
              </div>
            </div>

            {formError && (
              <div id="form-error" className="form-error is-errored">
                {formError}
              </div>
            )}

            <div className="form-section">
              <button className="button--primary" disabled={!isFormValid}>
                Update password
              </button>
            </div>

            <div className="form-extras">
              <div className="help-links">
                <Link onClick={dismiss}>Remind me later</Link>
              </div>
            </div>
          </Fieldset>
        </form>
      </div>
    </UnifiedAuthPageWrapper>
  );
};

export default ExpiringPassword;
