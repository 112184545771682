import { deactivateOrganization } from "./actions";
import { useMemo, useState } from "react";
import Error from "core/components/Error";
import ConfirmModal from "core/components/ConfirmModal";
import { Input } from "core/components/Form/styles";
import { useQueryClient } from "@tanstack/react-query";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useToaster from "core/hooks/useToaster";
import Typography from "core/components/Typography";

const DeactivateOrganizationModal = ({ organization, close }) => {
  const queryClient = useQueryClient();
  const { toaster } = useToaster();
  const mutation = useAuthenticatedMutation(deactivateOrganization);

  const [confirm, setConfirm] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isValid = useMemo(
    () => confirm === organization?.id,
    [confirm, organization]
  );

  const dismiss = () => {
    setError(null);
    close();
  };

  const onYes = async () => {
    if (!isValid) {
      setError("Incorrect organization id.");
      return;
    }

    setError(null);
    setIsSubmitting(true);

    try {
      await mutation({ organizationId: organization.id });
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
      toaster.success({ message: "Organization has been deactivated." });
      close();
    } catch (error) {
      console.error(error);
      setError(error);
    }

    setIsSubmitting(false);
  };

  return (
    <ConfirmModal
      visible={true}
      onYes={onYes}
      yesLabel="I understand the consequences, deactivate this organization."
      disabled={!isValid}
      isSubmitting={isSubmitting}
      dismiss={dismiss}
      title="Deactivate Organization"
      width="600px"
    >
      <Error>{error}</Error>
      <Typography variant="p">
        This will deactivate the <b>{organization.id}</b> organization.
      </Typography>
      <Typography variant="p">
        Please type <b>{organization.id}</b> to confirm.
      </Typography>
      <div style={{ paddingBottom: "15px" }}>
        <Input onChange={(e) => setConfirm(e.target.value)} />
      </div>
    </ConfirmModal>
  );
};

export default DeactivateOrganizationModal;
