import styled from "styled-components";
import ProductItem from "./ProductItem";

const StyledProductList = styled.ul`
  padding: 0px;
  margin: 0px;
  padding-top: 20px;
  padding-left: 30px;

  li {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    div:first-child {
      /* max-width: 325px; */
    }

    div:last-child {
      display: flex;
      gap: 10px;
    }
  }
`;

const ProductList = ({ isReadOnly, products, dataCy }) => {
  return (
    <StyledProductList data-cy={dataCy}>
      {products.map((product) => {
        return (
          <ProductItem
            key={product.id}
            toggleId={product.toggleId}
            name={product.meta.name}
            description={product.meta.description}
            productPath={product.productPath}
            isEnabled={product.isEnabled}
            handleEnabledChange={product.handleEnabledChange}
            maxControlsCount={product.totalControls}
            controlsConfiguredCount={product.controlsConfiguredCount}
            productHasError={product.hasError}
            isReadOnly={isReadOnly}
            dataCy={`${product.id}`}
          />
        );
      })}
    </StyledProductList>
  );
};

export default ProductList;
