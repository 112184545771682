import { useMemo, useState } from "react";
import { DateTime } from "luxon";

import Button from "core/components/Button";
import Typography from "core/components/Typography";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useToaster from "core/hooks/useToaster";
import {
  generateOrgDataAccessCredential,
  generateUserDataAccessCredential,
  disableIndividualCredentialById,
  disableOrganizationCredentialById,
} from "../actions";
import DownloadNewCredentialModal from "./DownloadNewCredentialModal";
import Error from "core/components/Error";
import { downloadJson } from "./util";
import ButtonLink from "core/components/ButtonLink";
import { Row } from "core/styles";
import { ROLES } from "core/constants";
import useCurrentUser from "core/hooks/useCurrentUser";
import { getLastActiveCredential } from "../utilities";
import DetailsViewer from "../DetailsViewer";
import { useParams } from "react-router-dom";
import ConfirmModal from "core/components/ConfirmModal";

const CREDENTIAL_PERMISSIONS = {
  CAN_DOWNLOAD: "permission:user:credentials:download",
  CAN_DISABLE: "permission:user:credentials:disable",
  CAN_VIEW_META: "permission:user:credentials:view-meta",
};

// Matt: I think we need to decompose this and consider a different pattern
const credSettings = {
  user: {
    text: `Download your individual credential for BQ.
        Individual credentials are valid for 30 days, at which point you will
        have to download new credentials.`,
    getDownloadInfo: (generatedTimeStamp) => {
      return generatedTimeStamp
        ? `${DateTime.fromMillis(generatedTimeStamp).toFormat("D")}`
        : "";
    },
    getExpiryInfo: (daysToExpire) => {
      if (daysToExpire === 0) {
        return "Expires Today";
      } else if (daysToExpire < 0) {
        return `Expired on ${DateTime.now()
          .plus({ days: daysToExpire })
          .toFormat("D")}`;
      } else if (daysToExpire > 0) {
        return `In ${daysToExpire} days on ${DateTime.now()
          .plus({ days: daysToExpire })
          .toFormat("D")}`;
      } else {
        return "";
      }
    },
    generateCredentialApi: generateUserDataAccessCredential,
    disableCredentialApi: disableIndividualCredentialById,
    toasterMessage: "You successfully downloaded your credential!",
    modalTitle: "Download New Credential?",
    modalContent: `Downloading a new individual credential will cause the old credential
      to expire and disconnect your data access until you upload your new credential.
      Do you still want to continue?`,
    disableModalTitle: "Disable Individual Credential?",
    disableModalDescription:
      "Disabling this credential will cause it to immediately expire. Do you still want to continue?",
    getFileName: (credJson) => {
      return credJson.client_email.split("@")[0];
    },
    getPermissions: ({ loggedInUser, formEntity: profileUser }) => {
      const isProfileCurrentUser = loggedInUser?.email === profileUser?.email;
      const isCurrentUserAdmin = [ROLES.orgAdmin, ROLES.systemAdmin].includes(
        loggedInUser.role
      );
      const isCurrentUserViewer = loggedInUser.role === ROLES.systemViewer;

      let permissions = [];

      if (isProfileCurrentUser) {
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_DOWNLOAD);
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_DISABLE);
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_VIEW_META);
      }

      if (isProfileCurrentUser || isCurrentUserAdmin) {
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_DISABLE);
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_VIEW_META);
      }

      if (isCurrentUserViewer) {
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_VIEW_META);
      }

      return permissions;
    },
  },
  org: {
    text: `Download your organizations credential for BQ.
    This credential does not expire.`,
    disableModalTitle: "Disable Organization Credential?",
    disableModalDescription:
      "Disabling this organization's credential will cause is to immediately expire. Do you still want to continue?",
    getDownloadInfo: (generatedTimeStamp, creatorEmail, organization) => {
      const creator = organization.users.find(
        (user) => user.email === creatorEmail
      );
      const creatorLink = creator?.email
        ? `/admin/org-management/${creator.organizationId}/users/${creator.email}/profile`
        : "";

      if (creatorLink) {
        return (
          <span>
            <ButtonLink
              variant="text"
              to={creatorLink}
              style={{ fontSize: "16px", padding: "0px", margin: "0px" }}
            >
              {creator.name || creator.email}
            </ButtonLink>{" "}
            on {DateTime.fromMillis(generatedTimeStamp).toFormat("D")}
          </span>
        );
      }

      return generatedTimeStamp
        ? `Credential downloaded on ${DateTime.fromMillis(
            generatedTimeStamp
          ).toFormat("D")}`
        : "";
    },
    getExpiryInfo: () => {
      return "";
    },
    generateCredentialApi: generateOrgDataAccessCredential,
    disableCredentialApi: disableOrganizationCredentialById,
    toasterMessage: "You successfully downloaded your credential!",
    modalTitle: "Download New Credential?",
    modalContent: `Downloading a new organization credential will cause the old credential to expire
      and disconnect your data access until you upload your new credential.
      Do you still want to continue?`,
    getFileName: (credJson) => {
      return credJson.client_email.split("@")[0];
    },
    getPermissions: ({ loggedInUser, formEntity: organization }) => {
      const isCurrentUserOrgMember =
        loggedInUser.organizationId === organization.id;
      const isCurrentUserAdmin = [ROLES.orgAdmin, ROLES.systemAdmin].includes(
        loggedInUser.role
      );
      const isCurrentUserPopuliAdmin = loggedInUser.role === ROLES.systemAdmin;
      const isCurrentUserViewer = loggedInUser.role === ROLES.systemViewer;

      let permissions = [];

      if (isCurrentUserOrgMember && isCurrentUserAdmin) {
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_DOWNLOAD);
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_DISABLE);
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_VIEW_META);
      }

      if (!isCurrentUserOrgMember && isCurrentUserPopuliAdmin) {
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_DISABLE);
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_VIEW_META);
      }

      if (isCurrentUserViewer) {
        permissions.push(CREDENTIAL_PERMISSIONS.CAN_VIEW_META);
      }

      return permissions;
    },
  },
};

export const CredentialNavSection = ({
  type,
  entity,
  isLoading,
  onMutationSuccess,
}) => {
  const { toaster } = useToaster();
  const { currentUser } = useCurrentUser();
  const params = useParams();
  const { email, orgId: organizationId } = params;

  const {
    text,
    getDownloadInfo,
    getExpiryInfo,
    generateCredentialApi,
    disableCredentialApi,
    getFileName,
    toasterMessage,
    modalTitle,
    modalContent,
    getPermissions,
    disableModalTitle,
    disableModalDescription,
  } = credSettings[type];

  const permissions = getPermissions({
    loggedInUser: currentUser,
    formEntity: entity,
  });

  const [isDownloading, setIsDownloading] = useState(false);
  const [showDownloadConfirmModal, setShowDownloadConfirmModal] =
    useState(false);
  const [isDisabling, setIsDisabling] = useState(false);
  const [showDisableConfirmModal, setShowDisableConfirmModal] = useState(false);
  const [error, setError] = useState(null);

  const generateCredential = useAuthenticatedMutation(generateCredentialApi);
  const disableCredential = useAuthenticatedMutation(disableCredentialApi);

  const activeCredentialInfo = useMemo(
    () => getLastActiveCredential(entity.data_access_info),
    [entity]
  );
  const hasActiveCredential = useMemo(
    () => !!activeCredentialInfo,
    [activeCredentialInfo]
  );

  const downloadDate = useMemo(
    () =>
      getDownloadInfo(
        activeCredentialInfo?.generated_timestamp,
        activeCredentialInfo?.created_by,
        entity
      ),
    [activeCredentialInfo, getDownloadInfo, entity]
  );

  const expiration = useMemo(
    () =>
      getExpiryInfo(
        activeCredentialInfo?.days_to_expire,
        activeCredentialInfo?.generated_timestamp
      ),
    [
      activeCredentialInfo?.days_to_expire,
      activeCredentialInfo?.generated_timestamp,
      getExpiryInfo,
    ]
  );

  const handleDownloadClick = async () => {
    if (hasActiveCredential) {
      setShowDownloadConfirmModal(true);
    } else {
      setError(null);
      setIsDownloading(true);

      try {
        const result = await generateCredential({
          organizationId,
          email: email,
        });
        const credential = result.credential;
        const fileName = getFileName(credential);
        downloadJson(credential, `credential-${fileName}`);

        // Notify
        toaster.success({
          message: toasterMessage,
        });

        // Bubble up successful action
        onMutationSuccess && onMutationSuccess();
      } catch (error) {
        console.error(error);
        setError(error);
      }

      setIsDownloading(false);
    }
  };

  const handleDisableClick = async () => {
    setShowDisableConfirmModal(true);
  };

  const handleDisableConfirm = async () => {
    setError(null);
    setIsDisabling(true);

    try {
      await disableCredential({
        email,
        organizationId,
        credentialId: activeCredentialInfo.credential_id,
      });

      // Notify
      toaster.success({
        message: "Credential successfully disabled!",
      });

      // Bubble up successful action
      onMutationSuccess && onMutationSuccess();
    } catch (error) {
      console.error(error);
      setError(error);
    }

    setIsDisabling(false);
    setShowDisableConfirmModal(false);
  };

  return (
    <>
      {/* Form Description */}
      {permissions.includes(CREDENTIAL_PERMISSIONS.CAN_DOWNLOAD) && (
        <Typography
          variant="p"
          noMargin
          style={{ lineHeight: "22px", marginBottom: "24px" }}
        >
          {text}
        </Typography>
      )}

      {/* Form Error */}
      <Error>{error}</Error>

      {/* Form Actions */}
      <Row style={{ gap: "16px" }}>
        {activeCredentialInfo &&
          permissions.includes(CREDENTIAL_PERMISSIONS.CAN_DISABLE) && (
            <Button
              variant="outlined"
              isLoading={isDisabling}
              onClick={handleDisableClick}
            >
              Disable Current
            </Button>
          )}

        {permissions.includes(CREDENTIAL_PERMISSIONS.CAN_DOWNLOAD) && (
          <Button
            type="submit"
            isLoading={isDownloading}
            onClick={handleDownloadClick}
          >
            Download Credential
          </Button>
        )}
      </Row>

      {/* Form Metadata */}
      {permissions.includes(CREDENTIAL_PERMISSIONS.CAN_VIEW_META) && (
        <DetailsViewer
          isLoading={isLoading}
          data={
            type === "user"
              ? [
                  { label: "Downloaded On", value: downloadDate },
                  { label: "Expiration", value: expiration },
                ]
              : [{ label: "Downloaded By", value: downloadDate }]
          }
        />
      )}

      {/* Modal to confirm if new credential is needed while one is already genrated and active*/}
      {showDownloadConfirmModal ? (
        <DownloadNewCredentialModal
          title={modalTitle}
          content={modalContent}
          close={() => setShowDownloadConfirmModal(false)}
          credSettings={{
            generateCredential,
            toasterMessage,
            getFileName,
            onMutationSuccess,
          }}
        />
      ) : null}

      <ConfirmModal
        visible={showDisableConfirmModal}
        onNo={() => setShowDisableConfirmModal(false)}
        dismiss={() => setShowDisableConfirmModal(false)}
        noLabel="Cancel"
        onYes={handleDisableConfirm}
        isSubmitting={isDisabling}
        title={disableModalTitle}
      >
        <Error>{error}</Error>
        <Typography variant="p" style={{ maxWidth: "500px" }}>
          {disableModalDescription}
        </Typography>
      </ConfirmModal>
    </>
  );
};
