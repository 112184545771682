import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import * as RadixAccordion from "@radix-ui/react-accordion";

const StyledIcon = styled(FontAwesomeIcon)`
  color: #000000;
  margin-right: 8px;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
`;

const AccordionRoot = styled(RadixAccordion.Root)``;

const StyledAccordionItem = styled(RadixAccordion.Item)`
  background-color: #f9f9f9;
  padding: 15px 20px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #dddbda;
`;

const AccordionHeader = styled(RadixAccordion.Header)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px;
  margin: 0px;

  button {
    font-size: 18px;
    font-family: "Inter";
    font-weight: 600;
    color: #3e3e3c;
  }
`;

const AccordionTrigger = styled(RadixAccordion.Trigger)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: unset;
  border: unset;
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 0px;

  .state-observer {
    transition: opacity 300ms linear;
  }

  &[data-state="open"] {
    .state-observer {
      opacity: 1;
    }
  }

  &[data-state="closed"] {
    svg.accordion-arrow {
      transform: rotate(-90deg);
    }

    .state-observer {
      opacity: 0;
    }
  }
`;

const slideDown = keyframes`
  from {
    height: 0px;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
  `;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;

const StyledAccordionContent = styled(RadixAccordion.Content)`
  overflow: hidden;
  &[data-state="open"] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-state="closed"] {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;

export const AccordionContent = ({ children }) => {
  return <StyledAccordionContent>{children}</StyledAccordionContent>;
};

export const AccordionItem = ({
  value,
  title,
  HeaderTitle,
  HeaderRight,
  children,
  customProps = {},
  dataCy,
}) => {
  return (
    <StyledAccordionItem value={value} data-cy={dataCy}>
      <AccordionHeader>
        <AccordionTrigger>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <StyledIcon
              className="accordion-arrow"
              icon={faChevronDown}
              aria-hidden
            />
            {HeaderTitle ? (
              <HeaderTitle title={title} {...customProps} />
            ) : (
              <div>{title}</div>
            )}
          </div>

          {HeaderRight && <HeaderRight />}
        </AccordionTrigger>
      </AccordionHeader>
      {children}
    </StyledAccordionItem>
  );
};

const Accordion = ({ openItems, setOpenItems, children }) => {
  return (
    <AccordionRoot
      type="multiple"
      value={openItems}
      onValueChange={setOpenItems}
    >
      {children}
    </AccordionRoot>
  );
};

export default Accordion;
