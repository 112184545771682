import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import { useState } from "react";
import { disableUserDataAccess } from "./actions";
import ConfirmModal from "core/components/ConfirmModal";
import { useQueryClient } from "@tanstack/react-query";
import Error from "core/components/Error";
import useToaster from "core/hooks/useToaster";
import Typography from "core/components/Typography";
import styled from "styled-components";

const ModalContentContainer = styled.div`
  width: 500px;
`;

const DisableUserDataAccessModal = ({ user, close }) => {
  const queryClient = useQueryClient();
  const { toaster } = useToaster();
  const disableDataAccess = useAuthenticatedMutation(disableUserDataAccess);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onNo = () => {
    setError(null);
    close();
  };

  const onYes = async () => {
    setError(null);
    setIsSubmitting(true);

    try {
      await disableDataAccess({ email: user.email });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toaster.success({ message: "Data access for user is disabled" });
      close();
    } catch (error) {
      console.error(error);
      setError(error);
    }

    setIsSubmitting(false);
  };

  return (
    <ConfirmModal
      visible={!!user}
      dismiss={onNo}
      onNo={onNo}
      noLabel="Cancel"
      onYes={onYes}
      yesLabel="Disable Access"
      isSubmitting={isSubmitting}
      title="Disable Direct Data Access?"
    >
      <ModalContentContainer>
        <Error>{error}</Error>
        <Typography variant="p">
          Disabling direct data access will cause {user.name}’s credentials to
          expire immediately. Do you want to proceed?
        </Typography>
      </ModalContentContainer>
    </ConfirmModal>
  );
};

export default DisableUserDataAccessModal;
