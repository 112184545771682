import { useEffect, useMemo, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { faFileCertificate } from "@fortawesome/pro-regular-svg-icons";
import { faBuildingUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import styled, { ThemeProvider } from "styled-components";
import { DateTime } from "luxon";
import {
  faListUl,
  faKey,
  faIdCardClip,
  faArrowLeft,
  faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck,
  faEllipsis,
  faExclamation,
  faBan,
} from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "react-tooltip";

import theme from "theme";
import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useCurrentUser from "core/hooks/useCurrentUser";
import OrgDetailsForm from "modules/admin/OrgDetailsForm";
import OrgUserLicenseForm from "modules/admin/OrgUserLicenseForm";
import OrgProductLicenseForm from "modules/admin/OrgProductLicenseForm";
import {
  getOrganizationDeployment,
  getOrganizationOnboarding,
  updateOrganization,
  setupOrganization,
  deployLicense,
  updateOrganizationLicenses,
  getUsers,
  getCustomerSegments,
  getProductCatalogVersion,
  getOrganizationById,
} from "modules/admin/actions";
import useToaster from "core/hooks/useToaster";
import { DEFAULT_ORG, FLAG_KEYS } from "core/constants";
import Typography from "core/components/Typography";
import { Column, Row } from "core/styles";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import Pill from "core/components/Pill";
import { SecondaryButton } from "core/components/QueryBuilder/styles";
import { InputGroup, Label } from "core/components/Form";
import Toggle from "core/components/Toggle";
import OrgIntegrationsForm from "modules/admin/OrgIntegrationsForm";
import OrgPreferencesForm from "modules/admin/OrgPreferencesForm";
import Flex from "core/components/Flex";
import { SideMenuContainer, SubNav } from "modules/admin/SubNav";
import { CredentialNavSection } from "modules/admin/CredentialNavSection";
import { ROLES } from "core/constants";
import DetailsViewer from "modules/admin/DetailsViewer";
import { useCheckFeatureFlag } from "core/hooks/featureFlagging";
import Utils from "common/utilities";

const ONBOARDING_LABELS = {
  "not onboarded": "Not Setup",
  timeout: "Timeout",
  "in progress": "In Progress",
  succeeded: "Active",
  failed: "Error",
  inactive: "Inactive",
  queued: "Queued",
  unknown: "Unknown",
};

const ONBOARDING_ICONS = {
  "not onboarded": faExclamation,
  timeout: faClose,
  "in progress": faEllipsis,
  succeeded: faCheck,
  failed: faClose,
  queued: faEllipsis,
  unknown: faExclamation,
  inactive: faBan,
};

const ONBOARDING_VARIANTS = {
  "not onboarded": "default",
  timeout: "failed",
  "in progress": "info",
  succeeded: "success",
  failed: "failed",
  queued: "pending",
  unknown: "warning",
  inactive: "default",
};

const DEPLOYMENT_STATUSES = {
  DEFAULT: "Not Configured",
  SUCCESS: "Deployed",
  IN_PROGRESS: "In Progress",
  FAILED: "Failed",
  TIMEOUT: "Timeout",
  QUEUED: "Queued",
  UNKNOWN: "Unknown",
};

const DEPLOYMENT_ICONS = {
  [DEPLOYMENT_STATUSES.DEFAULT]: faExclamation,
  [DEPLOYMENT_STATUSES.SUCCESS]: faCheck,
  [DEPLOYMENT_STATUSES.IN_PROGRESS]: faEllipsis,
  [DEPLOYMENT_STATUSES.FAILED]: faClose,
  [DEPLOYMENT_STATUSES.QUEUED]: faEllipsis,
  [DEPLOYMENT_STATUSES.TIMEOUT]: faClose,
  [DEPLOYMENT_STATUSES.UNKNOWN]: faExclamation,
};

const DEPLOYMENT_STATUS_MAP = {
  "not deployed": DEPLOYMENT_STATUSES.DEFAULT,
  "in progress": DEPLOYMENT_STATUSES.IN_PROGRESS,
  queued: DEPLOYMENT_STATUSES.QUEUED,
  succeeded: DEPLOYMENT_STATUSES.SUCCESS,
  failed: DEPLOYMENT_STATUSES.FAILED,
  timeout: DEPLOYMENT_STATUSES.TIMEOUT,
  unknown: DEPLOYMENT_STATUSES.UNKNOWN,
};

const DEPLOYMENT_STATUS_PILL_VARIANTS = {
  "not deployed": "default",
  "in progress": "info",
  queued: "pending",
  succeeded: "success",
  failed: "failed",
  timeout: "failed",
  unknown: "warning",
};

const REFETCH_SHORT = 10 * 1000;
const REFETCH_LONG = 60 * 1000;
const tooltipId = "statusTooltip";
const dateTimeFormat = "FF";
const durationFormat = "m'm ' s's'";

const StatusTooltip = ({ data }) => {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div>
          <Typography variant="h4" color="white">
            Summary
          </Typography>
          <Typography variant="p" color="white">
            <strong>Status:</strong> {data.status}
          </Typography>
          {data.message && (
            <Typography variant="p" color="white">
              <strong>Message:</strong> {data.message}
            </Typography>
          )}
          <Typography variant="p" color="white">
            <strong>Start Time:</strong>{" "}
            {data.licenseDeploymentStart?.toFormat(dateTimeFormat)}
          </Typography>
          {data.licenseDeploymentFinish && (
            <Typography variant="p" color="white">
              <strong>Duration:</strong>{" "}
              {data.licenseDeploymentFinish
                .diff(data.licenseDeploymentStart)
                .toFormat(durationFormat)}
            </Typography>
          )}
        </div>
        {["succeeded"].includes(data.rawStatus) && (
          <>
            <div>
              <Typography variant="h4" color="white">
                BI
              </Typography>
              <Typography variant="p" color="white">
                <strong>Success:</strong> {data.isBiDeployed}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Start Time:</strong>{" "}
                {data.biDeploymentStart.toFormat(dateTimeFormat)}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Duration:</strong>{" "}
                {data.biDeploymentFinish
                  .diff(data.biDeploymentStart)
                  .toFormat(durationFormat)}
              </Typography>
            </div>
            <div>
              <Typography variant="h4" color="white">
                Data
              </Typography>
              <Typography variant="p" color="white">
                <strong>Success:</strong> {data.isDataDeployed}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Start Time:</strong>{" "}
                {data.dataDeploymentStart.toFormat(dateTimeFormat)}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Duration:</strong>{" "}
                {data.dataDeploymentFinish
                  .diff(data.dataDeploymentStart)
                  .toFormat(durationFormat)}
              </Typography>
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

const buildStatusObject = (data, STATUS_MAP) => {
  const BI_MAPPER = {
    is_success: (boolean) => ({ isBiDeployed: String(boolean) }),
    start_timestamp: (timestamp) => ({
      biDeploymentStart: DateTime.fromMillis(timestamp),
    }),
    finish_timestamp: (timestamp) => ({
      biDeploymentFinish: DateTime.fromMillis(timestamp),
    }),
  };

  const DATA_MAPPER = {
    is_success: (boolean) => ({ isDataDeployed: String(boolean) }),
    start_timestamp: (timestamp) => ({
      dataDeploymentStart: DateTime.fromMillis(timestamp),
    }),
    finish_timestamp: (timestamp) => ({
      dataDeploymentFinish: DateTime.fromMillis(timestamp),
    }),
  };

  const SUMMARY_MAPPER = {
    status: (rawStatus) => ({
      rawStatus: rawStatus || "unknown",
      status: STATUS_MAP[rawStatus || "unknown"],
    }),
    message: (string) => ({ message: string }),
    deployment_number: (number) => ({ deploymentNumber: number }),
    start_timestamp: (timestamp) => ({
      licenseDeploymentStart: timestamp ? DateTime.fromMillis(timestamp) : null,
    }),
    finish_timestamp: (timestamp) => ({
      licenseDeploymentFinish: timestamp
        ? DateTime.fromMillis(timestamp)
        : null,
    }),
    last_successful_timestamp: (timestamp) => ({
      licenseLastSuccessfulDeployment: timestamp
        ? DateTime.fromMillis(timestamp)
        : null,
    }),
  };

  const defaultObject = {
    rawStatus: "unknown",
    status: STATUS_MAP["unknown"],
  };

  const biObject = data.bi
    ? Object.keys(data.bi).reduce((object, key) => {
        if (BI_MAPPER[key]) {
          return {
            ...object,
            ...BI_MAPPER[key](data.bi[key]),
          };
        }

        return object;
      }, {})
    : {};

  const dataObject = data.rows
    ? Object.keys(data.rows).reduce((object, key) => {
        if (DATA_MAPPER[key]) {
          return {
            ...object,
            ...DATA_MAPPER[key](data.rows[key]),
          };
        }

        return object;
      }, {})
    : {};

  const licenseObject = data
    ? Object.keys(data).reduce((object, key) => {
        if (SUMMARY_MAPPER[key]) {
          return {
            ...object,
            ...SUMMARY_MAPPER[key](data[key]),
          };
        }

        return object;
      }, {})
    : {};

  return {
    ...defaultObject,
    ...biObject,
    ...dataObject,
    ...licenseObject,
  };
};

/**
 *
 * Container Logic
 * - State Management
 * - API Interactions
 * - Error Handling
 * - Toaster
 *
 */

const DetailsNavigationMenu = ({
  onboarding,
  isOnboardingFetching,
  deployment,
  isDeploymentFetching,
  orgIsActive,
  locationFrom = "",
}) => {
  const {
    currentUser: { role },
  } = useCurrentUser();
  const hasDataAccessFeature = useCheckFeatureFlag(
    FLAG_KEYS.DATA_ACCESS_MANAGEMENT
  );

  return (
    <SideMenuContainer>
      <SubNav
        to="general"
        icon={faListUl}
        label="General"
        statusIcon={
          orgIsActive
            ? ONBOARDING_ICONS[onboarding?.rawStatus] || faExclamation
            : ONBOARDING_ICONS["inactive"]
        }
        statusVariant={
          orgIsActive
            ? ONBOARDING_VARIANTS[onboarding?.rawStatus] || "default"
            : "default"
        }
        isStatusLoading={isOnboardingFetching}
        locationState={{ from: locationFrom }}
      />

      {[ROLES.systemAdmin, ROLES.systemViewer].includes(role) && (
        <SubNav
          to="bi"
          icon={faIdCardClip}
          label="User Licenses"
          locationState={{ from: locationFrom }}
        />
      )}

      {role === ROLES.systemAdmin && (
        <SubNav
          to="product"
          icon={faKey}
          label="Product Licenses"
          statusIcon={
            DEPLOYMENT_ICONS[DEPLOYMENT_STATUS_MAP[deployment?.rawStatus]]
          }
          statusVariant={DEPLOYMENT_STATUS_PILL_VARIANTS[deployment?.rawStatus]}
          isStatusLoading={isDeploymentFetching}
          isDisabled={
            orgIsActive
              ? ONBOARDING_VARIANTS[onboarding?.rawStatus] !== "success"
              : true
          }
          locationState={{ from: locationFrom }}
        />
      )}

      {[ROLES.orgAdmin, ROLES.systemViewer, ROLES.systemAdmin].includes(role) &&
        hasDataAccessFeature && (
          <SubNav
            to="credentials"
            icon={faFileCertificate}
            label="Data Credentials"
            isDisabled={
              orgIsActive
                ? ONBOARDING_VARIANTS[onboarding?.rawStatus] !== "success"
                : true
            }
            locationState={{ from: locationFrom }}
          />
        )}

      {role === ROLES.systemAdmin && (
        <SubNav
          to="integrations"
          icon={faSliders}
          label="Integrations"
          isDisabled={
            orgIsActive
              ? ONBOARDING_VARIANTS[onboarding?.rawStatus] !== "success"
              : true
          }
          locationState={{ from: locationFrom }}
        />
      )}

      {[ROLES.systemAdmin, ROLES.systemViewer].includes(role) && (
        <SubNav
          to="preferences"
          icon={faSliders}
          label="Preferences"
          isDisabled={
            orgIsActive
              ? ONBOARDING_VARIANTS[onboarding?.rawStatus] !== "success"
              : true
          }
          locationState={{ from: locationFrom }}
        />
      )}
    </SideMenuContainer>
  );
};

const HiddenElement = styled.div`
  margin-top: 15px;
  margin-left: 10px;
  margin-bottom: -15px;
`;

const VisibleOnHoverContainer = styled.span`
  ${HiddenElement} {
    opacity: 0;
  }

  &:hover {
    ${HiddenElement} {
      display: block;
      opacity: 1;
    }
  }
`;

const ContainerWithHiddenElement = ({
  isElementVisible,
  isElementVisibleOnHover,
  hiddenElement,
  children,
}) => {
  if (isElementVisible) {
    return (
      <div>
        {children}
        <HiddenElement>{hiddenElement}</HiddenElement>
      </div>
    );
  }
  if (isElementVisibleOnHover) {
    return (
      <VisibleOnHoverContainer>
        {children}
        <HiddenElement>{hiddenElement}</HiddenElement>
      </VisibleOnHoverContainer>
    );
  }

  return children;
};

const UpdateOrg = () => {
  const { toaster } = useToaster();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const [onboardingRefetchInterval, setOnboardingRefetchInterval] =
    useState(REFETCH_LONG);
  const [deploymentRefetchInterval, setDeploymentRefetchInterval] =
    useState(REFETCH_LONG);

  const { orgId: organizationId } = params;

  useEffect(() => {
    const pathname = location.pathname; // this gives me current Url's pathname
    localStorage.setItem("pageUrl", pathname);
  }, [location.pathname]);

  const {
    data: allUsers,
    isError: isUsersError,
    error: usersError,
  } = useQuery({
    queryKey: ["users", organizationId],
    queryFn: useAuthenticatedCall(getUsers),
    placeholderData: [],
  });

  useEffect(() => {
    if (isUsersError) {
      if (Array.isArray(usersError)) {
        toaster.error({ message: usersError[0] });
      } else {
        toaster.error({ message: usersError.message });
      }
    }
  }, [isUsersError, usersError, toaster]);

  const fetchQueryCall = useAuthenticatedCall((req) =>
    getOrganizationById({ ...req, organizationId })
  );

  const {
    isLoading: isFetching,
    isError: isOrgError,
    error: orgError,
    data: organization,
    isPlaceholderData: isPlaceholderOrg,
  } = useQuery({
    queryKey: ["organizations", organizationId],
    queryFn: fetchQueryCall,
    placeholderData: DEFAULT_ORG,
    select: (org) => {
      // Merge org with default
      return {
        ...DEFAULT_ORG,
        ...org,
        primaryContact: {
          ...DEFAULT_ORG.primaryContact,
          ...org?.primaryContact,
        },
        domain: org?.domain || [],
        licenses: {
          ...DEFAULT_ORG.licenses,
          ...org?.licenses,
          tableau_user_licenses: {
            ...DEFAULT_ORG.licenses.tableau_user_licenses,
            ...org?.licenses?.tableau_user_licenses,
          },
        },
        preferences: {
          bi_portal: {
            default_states:
              org?.preferences?.bi_portal?.default_states[0] ||
              DEFAULT_ORG.preferences.bi_portal.default_states,
          },
        },
        users: allUsers,
      };
    },
  });

  useEffect(() => {
    if (isOrgError) {
      if (Array.isArray(orgError)) {
        toaster.error({ message: orgError[0] });
      } else {
        toaster.error({ message: orgError.message });
      }
    }
  }, [isOrgError, orgError, toaster]);

  // fetch onboarding data
  const fetchOnboardingCall = useAuthenticatedCall((req) =>
    getOrganizationOnboarding({ ...req, organizationId })
  );
  const {
    isLoading: isOnboardingFetching,
    // isRefetching: isOnboardingRefetching,
    data: onboarding,
  } = useQuery({
    queryKey: ["organization-onboarding", organizationId],
    queryFn: fetchOnboardingCall,
    staleTime: onboardingRefetchInterval,
    refetchInterval: onboardingRefetchInterval,
    select: (data) => {
      const formattedData = buildStatusObject(data, ONBOARDING_LABELS);

      return {
        ...formattedData,
        tooltip: renderToStaticMarkup(<StatusTooltip data={formattedData} />),
      };
    },
  });
  // update onboarding refetchInterval based on status
  useEffect(() => {
    if (
      onboardingRefetchInterval === REFETCH_SHORT &&
      [
        "not onboarded",
        "timeout",
        "inactive",
        "unknown",
        "failed",
        "succeeded",
      ].includes(onboarding?.rawStatus)
    ) {
      setOnboardingRefetchInterval(REFETCH_LONG);
    }

    if (
      onboardingRefetchInterval === REFETCH_LONG &&
      ![
        "not onboarded",
        "timeout",
        "inactive",
        "unknown",
        "failed",
        "succeeded",
      ].includes(onboarding?.rawStatus)
    ) {
      setOnboardingRefetchInterval(REFETCH_SHORT);
    }
  }, [onboarding?.rawStatus, onboardingRefetchInterval]);

  // fetch deployment data
  const fetchDeploymentCall = useAuthenticatedCall((req) =>
    getOrganizationDeployment({ ...req, organizationId })
  );
  const {
    isLoading: isDeploymentFetching,
    data: deployment,
    refetch: refetchDeploymentStatus,
    isRefetching: isRefetchingDeploymentStatus,
  } = useQuery({
    queryKey: ["organization-deployment", organizationId],
    queryFn: fetchDeploymentCall,
    staleTime: deploymentRefetchInterval,
    refetchInterval: deploymentRefetchInterval,
    enabled: onboarding && onboarding.rawStatus === "succeeded",
    select: (data) => {
      const formattedData = buildStatusObject(data, DEPLOYMENT_STATUS_MAP);

      return {
        ...formattedData,
        tooltip: renderToStaticMarkup(<StatusTooltip data={formattedData} />),
      };
    },
  });

  // update deployment refetchInterval based on status
  useEffect(() => {
    if (
      deploymentRefetchInterval === REFETCH_SHORT &&
      [
        "not deployed",
        "timeout",
        "inactive",
        "unknown",
        "failed",
        "succeeded",
      ].includes(deployment?.rawStatus)
    ) {
      setDeploymentRefetchInterval(REFETCH_LONG);
    }

    if (
      deploymentRefetchInterval === REFETCH_LONG &&
      ![
        "not deployed",
        "timeout",
        "inactive",
        "unknown",
        "failed",
        "succeeded",
      ].includes(deployment?.rawStatus)
    ) {
      setDeploymentRefetchInterval(REFETCH_SHORT);
    }
  }, [deployment?.rawStatus, deploymentRefetchInterval]);

  const orgDetails = useMemo(() => {
    const {
      id,
      name,
      domain,
      primaryContact,
      active,
      customer_segment,
      medicare_licensed,
    } = organization;

    return {
      id,
      name,
      domain,
      primaryContact,
      active,
      customer_segment,
      medicare_licensed,
    };
  }, [organization]);
  const integrations = useMemo(() => {
    const { callbackUrls } = organization;

    return { callbackUrls };
  }, [organization]);

  const userLicense = useMemo(() => {
    const { user_limit, tableau_user_licenses } = organization.licenses;

    return {
      licenses: {
        user_limit,
        tableau_user_licenses,
      },
    };
  }, [organization]);

  const preferences = useMemo(
    () => ({ preferences: organization.preferences }),
    [organization]
  );

  const updateOrg = useAuthenticatedMutation((req) =>
    updateOrganization({
      ...req,
      organizationId,
    })
  );

  const retryOrgSetup = useAuthenticatedMutation((req) =>
    setupOrganization({ ...req, organizationId })
  );

  const retryLicenseDeployment = useAuthenticatedMutation((req) =>
    deployLicense({ ...req, organizationId })
  );

  const updateLicenses = useAuthenticatedMutation((req) => {
    return updateOrganizationLicenses({ ...req, organizationId });
  });

  const orgMutation = useMutation({
    mutationFn: updateOrg,
    onSuccess: (data, updatedOrg) => {
      /**
       * Since API calls are very slow, we update the cache with the updates for both the
       * single org's cache, and the org list.
       *
       * Then when we invalidate both queries since we don't receive full documents from response.
       *
       */

      // Update local list and detail query cache
      queryClient.setQueryData(
        ["organizations", organizationId],
        (cachedOrg) => {
          if (!cachedOrg) {
            return {
              ...updatedOrg,
              isUpdated: true,
            };
          }

          return { ...cachedOrg, ...updatedOrg, isUpdated: true };
        }
      );
      queryClient.setQueryData(["organizations"], (cachedOrgList) => {
        if (!cachedOrgList || cachedOrgList.length === 0) {
          return [
            {
              ...updatedOrg,
              isUpdated: true,
            },
          ];
        }

        return cachedOrgList.map((cachedOrg) => {
          if (cachedOrg.id === organizationId) {
            return { ...cachedOrg, ...updatedOrg, isUpdated: true };
          }

          return cachedOrg;
        });
      });

      // Invalidate list and detail queries
      queryClient.invalidateQueries({ queryKey: ["organizations"] });

      // Notify
      toaster.success({ message: "Organization has been updated." });
    },
  });

  const setupRetryMutation = useMutation({
    mutationFn: retryOrgSetup,
    onSuccess: async (data, org) => {
      // Notify
      toaster.success({
        message: "Organization setup retry has been started.",
      });
    },
    onError: async (error, variables, context) => {
      console.error("setupRetryMutation", { error, variables, context });
      // Notify
      toaster.error({ message: "Organization setup retry failed." });
    },
    onSettled: () => {
      // Optimistically update status to in progress
      queryClient.setQueryData(
        ["organization-onboarding", organizationId],
        (previous) => {
          return { ...previous, status: "in progress" };
        }
      );
    },
  });

  const licenseDeployRetryMutation = useMutation({
    mutationFn: retryLicenseDeployment,
    onSuccess: async (data, org) => {
      refetchDeploymentStatus();

      // Notify
      toaster.success({
        message: "License deployment retry has been started.",
      });
    },
    onError: async (error, variables, context) => {
      console.error("licenseDeployRetryMutation", {
        error,
        variables,
        context,
      });
      // Notify
      toaster.error({ message: "License deployment retry failed." });
    },
  });

  const licenseMutation = useMutation({
    mutationFn: updateLicenses,
    onSuccess: (data, licenseUpdates) => {
      // Invalidate cached query results
      queryClient.invalidateQueries({ queryKey: ["organizations"] });

      // Notify
      toaster.success({
        message: "Licenses have been updated.",
      });
    },
  });

  const handleDetailsFormSubmit = orgMutation.mutate;
  const handleUserLicenseFormSubmit = licenseMutation.mutate;
  const handlePreferencesFormSubmit = (formData) => {
    const selectedDefaultState = formData.preferences.bi_portal.default_states;

    return orgMutation.mutate({
      preferences: {
        bi_portal: {
          default_states: [selectedDefaultState],
        },
      },
    });
  };

  const [showCodeView, setShowCodeView] = useState(false);
  const [isHoldingShift, setHoldingShift] = useState(false);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.key === "Shift") {
        setHoldingShift(true);
      }
    };

    const onKeyUp = (event) => {
      if (event.key === "Shift") {
        setHoldingShift(false);
      }
    };

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
    // eslint-disable-next-line
  }, []);

  const orgStatus = organization?.active
    ? ONBOARDING_LABELS[onboarding?.rawStatus || "unknown"]
    : ONBOARDING_LABELS["inactive"];

  const orgVariant = organization?.active
    ? ONBOARDING_VARIANTS[onboarding?.rawStatus || "unknown"]
    : ONBOARDING_VARIANTS["inactive"];

  const {
    currentUser: { role },
  } = useCurrentUser();

  const fetchCustomerSegments = useAuthenticatedCall(getCustomerSegments);
  const { data: selectedCustomerSegment } = useQuery({
    queryKey: ["customer-segments", null, {}],
    queryFn: fetchCustomerSegments,
    enabled: !!orgDetails?.customer_segment,
    select: (data) => {
      const customerSegment = Object.values(data).find(
        (item) => item.id === orgDetails?.customer_segment
      );
      const customerSegmentName = customerSegment?.name;

      const isMedicareLicensable = Object.values(data).find(
        (item) => item.id === orgDetails?.customer_segment
      )?.is_medicare_licensable;

      const env = Utils.getEnv();
      const isDevEnv = env === "development";
      const isPrOrg = isDevEnv && customerSegment?.pr_org === orgDetails?.id;

      return {
        customerSegmentName,
        isMedicareLicensable,
        isPrOrg,
      };
    },
  });

  const fetchCatalogVersion = useAuthenticatedCall((req) => {
    return getProductCatalogVersion({ ...req, isPrCatalog: true });
  });
  const { data: prNumberForPrCatalog } = useQuery({
    queryKey: ["pr-catalog-version"],
    queryFn: fetchCatalogVersion,
    enabled: !!selectedCustomerSegment?.isPrOrg,
    select: (data) => {
      return data?.pr_number;
    },
  });

  const { from, orgId } = location.state || {};

  return (
    <div style={{ position: "relative", padding: "80px 64px" }}>
      <div
        style={{
          position: "absolute",
          top: "30px",
        }}
      >
        <SecondaryButton
          onClick={() => {
            if (from === "UserManagement") {
              navigate(`/admin/org-management/${organizationId}`);
            } else if (from === "OrgUsers") {
              navigate(`/admin/org-management/${orgId}`);
            } else {
              navigate("/admin/org-management");
            }
          }}
          style={{ display: "flex", width: "fit-content" }}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{ marginRight: "5px", marginBottom: "-1px" }}
          />
          <Typography variant="sub-text" noMargin>
            {["UserManagement", "OrganizationDetails", "OrgUsers"].includes(
              from
            )
              ? "Back to Org Users"
              : "Back to Organizations"}
          </Typography>
        </SecondaryButton>
      </div>

      <Flex direction="column">
        <AnimatePresence mode="wait">
          <Flex directionSm="column" direction="row" gap="7vw">
            <Flex direction="column">
              <Column gap="large" alignItems="start">
                <Row gap="15px" alignItems="center">
                  <FontAwesomeIcon
                    icon={faBuildingUser}
                    style={{
                      width: "48px",
                      height: "48px",
                      padding: "10px 12px",
                      paddingBottom: "11px",
                      color: "#fff",
                      backgroundColor: "#D68712",
                      borderRadius: "5px",
                    }}
                  />
                  <div>
                    <Typography
                      variant="h3"
                      noMargin
                      style={{ maxWidth: "230px" }}
                    >
                      {isFetching || isPlaceholderOrg || isOrgError ? (
                        <Skeleton style={{ width: "150px" }} />
                      ) : (
                        organization.name || "n/a"
                      )}
                    </Typography>
                    <Typography variant="h5" noMargin>
                      {isFetching || isPlaceholderOrg || isOrgError ? (
                        <Skeleton style={{ width: "100px" }} />
                      ) : (
                        `ID: ${organization.id || "—"}`
                      )}
                    </Typography>
                  </div>
                </Row>

                <ContainerWithHiddenElement
                  hiddenElement={
                    <InputGroup
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "fit-content",
                        gap: "8px",
                      }}
                    >
                      <Label style={{ textAlign: "center" }}>Code View</Label>
                      <Toggle
                        id="showCodeView"
                        value={showCodeView}
                        onChange={setShowCodeView}
                      />
                    </InputGroup>
                  }
                  isElementVisibleOnHover={
                    location.pathname.includes("product") && isHoldingShift
                  }
                  isElementVisible={
                    location.pathname.includes("product") && showCodeView
                  }
                >
                  <DetailsNavigationMenu
                    onboarding={onboarding}
                    isOnboardingFetching={isOnboardingFetching}
                    deployment={deployment}
                    isDeploymentFetching={isDeploymentFetching}
                    orgIsActive={organization?.active}
                    locationFrom={from}
                  />
                </ContainerWithHiddenElement>

                <div id="portal__devtools" />
              </Column>
            </Flex>

            <Flex direction="column" minWidth="450px" width="min-content">
              <Routes>
                <Route
                  index
                  element={<Navigate replace to="general" state={{ from }} />}
                />
                <Route
                  path="general"
                  element={
                    <div>
                      <Flex
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        marginBottom="5px"
                      >
                        <Typography variant="h2" noMargin>
                          General
                        </Typography>
                        <div>
                          {onboarding ? (
                            <Pill
                              text={orgStatus}
                              size="large"
                              variant={orgVariant}
                              tooltipId={tooltipId}
                              textDataCy="onboardingStatus"
                              tooltipStrategy="fixed"
                              tooltipContent={
                                organization?.active
                                  ? ["succeeded", "timeout", "failed"].includes(
                                      onboarding.rawStatus
                                    )
                                    ? onboarding.tooltip
                                    : null
                                  : null
                              }
                            />
                          ) : (
                            <Skeleton
                              style={{ width: "50px", height: "30px" }}
                            />
                          )}
                        </div>
                      </Flex>
                      {role === ROLES.systemAdmin ? (
                        <OrgDetailsForm
                          onSubmit={handleDetailsFormSubmit}
                          onCancel={
                            ["succeeded", "in progress"].includes(
                              onboarding?.rawStatus
                            )
                              ? null
                              : setupRetryMutation.mutate
                          }
                          initialValues={orgDetails}
                          error={orgMutation.error}
                          isFetching={
                            isPlaceholderOrg || isFetching || isOrgError
                          }
                          isSubmitting={orgMutation.isPending}
                          isCancelling={setupRetryMutation.isPending}
                        />
                      ) : (
                        <DetailsViewer
                          isLoading={isPlaceholderOrg || isFetching}
                          data={[
                            {
                              label: "Organization Name",
                              value: orgDetails?.name,
                            },
                            { label: "Organization ID", value: orgDetails?.id },
                            {
                              label: "Primary Contact",
                              value: orgDetails?.primaryContact?.name,
                            },
                            {
                              label: "Primary Email",
                              value: orgDetails?.primaryContact?.email,
                            },
                            {
                              label: "Domain",
                              value: orgDetails?.domain,
                            },
                            {
                              label: "Customer Segment",
                              value:
                                selectedCustomerSegment?.customerSegmentName,
                            },
                            ...(selectedCustomerSegment?.isMedicareLicensable
                              ? [
                                  {
                                    label: "Is Medicare QE Included?",
                                    value: orgDetails?.medicare_licensed
                                      ? "Yes"
                                      : "No",
                                  },
                                ]
                              : []),
                          ]}
                        />
                      )}
                    </div>
                  }
                />

                <Route
                  path="bi"
                  element={
                    <motion.div>
                      <Typography variant="h2">User Licenses</Typography>

                      {role === ROLES.systemAdmin ? (
                        <OrgUserLicenseForm
                          onSubmit={handleUserLicenseFormSubmit}
                          submitLabel="Save Changes"
                          defaultValues={userLicense}
                          error={licenseMutation.error}
                          isFetching={
                            isPlaceholderOrg || isFetching || isOrgError
                          }
                          isSubmitting={licenseMutation.isPending}
                          disabledUntilTouched={true}
                        />
                      ) : (
                        <DetailsViewer
                          isLoading={isPlaceholderOrg || isFetching}
                          data={[
                            {
                              label: "User Limit",
                              value: userLicense?.licenses?.user_limit,
                            },
                            {
                              label: "BI Portal Explorer",
                              value:
                                userLicense?.licenses?.tableau_user_licenses
                                  ?.explorer_count,
                            },
                            {
                              label: "BI Portal Viewer",
                              value:
                                userLicense?.licenses?.tableau_user_licenses
                                  ?.viewer_count,
                            },
                            {
                              label: "BI Portal Creator",
                              value:
                                userLicense?.licenses?.tableau_user_licenses
                                  ?.creator_count,
                            },
                          ]}
                        />
                      )}
                    </motion.div>
                  }
                />

                <Route
                  path="integrations"
                  element={
                    <motion.div style={{ marginBottom: "30vh" }}>
                      <Typography variant="h2">Integrations</Typography>
                      <Typography variant="p">
                        Manage callback URLs for integrations.
                      </Typography>
                      <OrgIntegrationsForm
                        onSubmit={handleDetailsFormSubmit}
                        submitLabel="Save Changes"
                        initialValues={integrations}
                        error={orgMutation.error}
                        isFetching={
                          isPlaceholderOrg || isFetching || isOrgError
                        }
                        isSubmitting={orgMutation.isPending}
                      />
                    </motion.div>
                  }
                />

                <Route
                  path="preferences"
                  element={
                    <motion.div style={{ marginBottom: "30vh" }}>
                      <Typography variant="h2">Preferences</Typography>
                      {role === ROLES.systemAdmin ? (
                        <>
                          <Typography variant="p">
                            Changes to the Default State will result in a
                            redeployment of the Product License.
                          </Typography>
                          <OrgPreferencesForm
                            onSubmit={handlePreferencesFormSubmit}
                            submitLabel="Save Changes"
                            defaultValues={preferences}
                            error={orgMutation.error}
                            isFetching={
                              isPlaceholderOrg || isFetching || isOrgError
                            }
                            isSubmitting={orgMutation.isPending}
                          />
                        </>
                      ) : (
                        <DetailsViewer
                          isLoading={isPlaceholderOrg || isFetching}
                          data={[
                            {
                              label: "Default State",
                              value:
                                preferences?.preferences?.bi_portal
                                  ?.default_states.length > 0
                                  ? preferences?.preferences?.bi_portal
                                      ?.default_states
                                  : "None",
                            },
                          ]}
                        />
                      )}
                    </motion.div>
                  }
                />

                <Route
                  path="product/*"
                  element={
                    <motion.div style={{ minWidth: "650px" }}>
                      <OrgProductLicenseForm
                        submitLabel="Save Changes"
                        defaultValues={organization}
                        isFetching={
                          isPlaceholderOrg ||
                          isFetching ||
                          isRefetchingDeploymentStatus ||
                          isOrgError
                        }
                        licenseDeployment={deployment}
                        submitLicenseDeploymentRetry={
                          licenseDeployRetryMutation
                        }
                        refetchDeploymentStatus={refetchDeploymentStatus}
                        showCodeView={showCodeView}
                        customerSegment={{
                          segment: orgDetails?.customer_segment,
                          medicareLicensed: orgDetails?.medicare_licensed,
                          isPrOrg: selectedCustomerSegment?.isPrOrg,
                          prNumber: prNumberForPrCatalog,
                        }}
                      />
                    </motion.div>
                  }
                />
                <Route
                  path="credentials"
                  element={
                    <motion.div style={{ marginBottom: "30vh" }}>
                      <Typography variant="h2">Data Credentials</Typography>

                      <CredentialNavSection
                        type="org"
                        entity={organization}
                        isLoading={isPlaceholderOrg || isFetching}
                        onMutationSuccess={() => {
                          queryClient.invalidateQueries({
                            queryKey: ["organizations"],
                          });
                        }}
                      />
                    </motion.div>
                  }
                />
              </Routes>
            </Flex>
          </Flex>
        </AnimatePresence>
      </Flex>
      <Tooltip
        id={tooltipId}
        style={{ maxWidth: "450px", zIndex: "10000" }}
        offset={15}
      />
    </div>
  );
};

export default UpdateOrg;
