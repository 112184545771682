import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { faCity } from "@fortawesome/pro-regular-svg-icons";
import { ErrorBoundary } from "@datadog/browser-rum-react";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import useCurrentUser from "core/hooks/useCurrentUser";
import useToaster from "core/hooks/useToaster";
import PageHeader from "core/components/PageHeader";
import Search from "core/components/Search";
import Button from "core/components/Button";
import TableErrorFallback from "core/components/Table/TableErrorFallback";
import OrganizationTable from "modules/admin/OrganizationTable";
import { getOrganizations } from "modules/admin/actions";
import { Row } from "core/styles";
import { ROLES } from "core/constants";
import useNavigation from "core/hooks/useNavigation.js";

const OrganizationManagement = () => {
  const navigate = useNavigate();
  const { toaster } = useToaster();
  const location = useLocation();

  const fetchQueryCall = useAuthenticatedCall(getOrganizations);
  const {
    isLoading,
    data: organizations,
    fetchStatus,
    isError,
    error,
  } = useQuery({
    queryKey: ["organizations"],
    queryFn: fetchQueryCall,
    select: (data) => {
      const attributeTypeCounts = data
        .filter((organization) => {
          return (
            organization.licenses?.products?.identified_consumers?.license
              .purchased_attributes?.length > 0
          );
        })
        .map((organization) => {
          return {
            id: organization.id,
            purchasedAttributes:
              organization.licenses?.products?.identified_consumers?.license
                .purchased_attributes,
            attributeTypeCounts:
              organization.licenses?.products?.identified_consumers?.license.purchased_attributes.reduce(
                (attributeTypeCounts, currentAttribute) => {
                  if (currentAttribute.includes("_score")) {
                    attributeTypeCounts.scoreCount++;
                    attributeTypeCounts.score.push(currentAttribute);
                  } else if (currentAttribute.includes("_decile")) {
                    attributeTypeCounts.decileCount++;
                    attributeTypeCounts.decile.push(currentAttribute);
                  } else {
                    attributeTypeCounts.otherCount++;
                    attributeTypeCounts.other.push(currentAttribute);
                  }

                  return attributeTypeCounts;
                },
                {
                  decile: [],
                  decileCount: 0,
                  score: [],
                  scoreCount: 0,
                  other: [],
                  otherCount: 0,
                }
              ),
          };
        })
        .map((organization) => {
          organization.missingScoreAttributesFor =
            organization.attributeTypeCounts.decile.filter(
              (decileAttribute) => {
                const scoreCounterpart = decileAttribute.replace(
                  "_decile",
                  "_score"
                );
                return !organization.purchasedAttributes.includes(
                  scoreCounterpart
                );
              }
            );
          organization.missingDecileAttributesFor =
            organization.attributeTypeCounts.score.filter((scoreAttribute) => {
              const decileCounterpart = scoreAttribute.replace(
                "_score",
                "_decile"
              );
              return !organization.purchasedAttributes.includes(
                decileCounterpart
              );
            });

          organization.hasMissingAttributes =
            organization.missingDecileAttributesFor.length > 0 ||
            organization.missingScoreAttributesFor.length > 0;

          organization.hasOtherAttributes =
            organization.attributeTypeCounts.other.length > 0;

          organization.otherAttributes = organization.attributeTypeCounts.other;

          delete organization.attributeTypeCounts;

          return organization;
        })
        .filter((organization) => {
          return (
            organization.hasMissingAttributes || organization.hasOtherAttributes
          );
        });

      window.attributeTypeCounts = attributeTypeCounts;

      return data;
    },
  });

  useEffect(() => {
    if (isError) {
      if (Array.isArray(error)) {
        toaster.error({ message: error[0] });
      } else {
        toaster.error({ message: error.message });
      }
    }
  }, [isError, error, toaster]);

  useEffect(() => {
    const pathname = location.pathname; // this gives me current Url's pathname
    localStorage.setItem("pageUrl", pathname);
  }, [location.pathname]);

  const { orgSearchValue, setOrgSearchValue } = useNavigation();
  const handleSearchChange = (event) => {
    setOrgSearchValue(event.target.value);
  };

  const {
    currentUser: { role },
  } = useCurrentUser();
  const canUpdateOrganizations = role === ROLES.systemAdmin;

  return (
    <div style={{ position: "relative", padding: "30px 24px" }}>
      <PageHeader
        title="Organizations"
        icon={faCity}
        iconBackgroundColor="#D68712"
        iconAlt="Organizations"
        PageActionsComponent={
          <Row>
            <Search value={orgSearchValue} onChange={handleSearchChange} />
            {canUpdateOrganizations && (
              <Button
                width="auto"
                onClick={() => navigate("/admin/org-management/create")}
              >
                Create Organization
              </Button>
            )}
          </Row>
        }
      />
      <ErrorBoundary onError={console.error} fallback={TableErrorFallback}>
        <OrganizationTable
          data={organizations || []}
          isRefreshing={fetchStatus === "fetching"}
          isLoading={isLoading}
          canUpdate={canUpdateOrganizations}
          searchFilter={{
            searchValue: orgSearchValue,
            columns: [
              "name",
              "id",
              "contactName",
              "contactEmail",
              "contactPhone",
              "domain",
            ],
          }}
        />
      </ErrorBoundary>
    </div>
  );
};

export default OrganizationManagement;
