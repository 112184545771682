import { useCallback, useContext, useMemo } from "react";
import ToastContext from "../context/ToastContext";

let toastId = 0;

const useToaster = () => {
  const { toasts, setToasts } = useContext(ToastContext);

  const dismissById = useCallback(
    (id) => {
      let onDismiss;

      setToasts((prevToastMap) => {
        const { [id]: dismissedToast, ...remainingToasts } = prevToastMap;

        onDismiss = dismissedToast?.onDismiss;
        onDismiss && onDismiss();

        return remainingToasts;
      });
    },
    [setToasts]
  );

  const createToast = useCallback(
    ({
      type,
      title,
      message,
      duration = 3000,
      isInfinite = false,
      containerId = "default",
      onDismiss,
    }) => {
      const id = toastId;

      setToasts((prevToastMap) => {
        return {
          ...prevToastMap,
          [id]: {
            id,
            type,
            title,
            message,
            isInfinite,
            duration,
            containerId,
            onDismiss,
          },
        };
      });

      if (!isInfinite) {
        setTimeout(() => {
          dismissById(id);
        }, duration);
      }

      toastId++;

      return id;
    },
    [dismissById, setToasts]
  );

  const success = useCallback(
    ({
      title = "Success",
      message,
      isInfinite,
      duration,
      containerId,
      onDismiss,
    }) =>
      createToast({
        type: "success",
        title,
        message,
        isInfinite,
        duration,
        containerId,
        onDismiss,
      }),
    [createToast]
  );

  const error = useCallback(
    ({
      title = "Error",
      message = "Something went wrong on our end.",
      isInfinite,
      duration,
      containerId,
      onDismiss,
    }) =>
      createToast({
        type: "error",
        title,
        message,
        isInfinite,
        duration,
        containerId,
        onDismiss,
      }),
    [createToast]
  );

  const info = useCallback(
    ({
      title = "Info",
      message,
      isInfinite,
      duration,
      containerId,
      onDismiss,
    }) =>
      createToast({
        type: "info",
        title,
        message,
        isInfinite,
        duration,
        containerId,
        onDismiss,
      }),
    [createToast]
  );

  const basic = useCallback(
    ({
      title = "Default",
      message,
      isInfinite,
      duration,
      containerId,
      onDismiss,
    }) =>
      createToast({
        type: "default",
        title,
        message,
        isInfinite,
        duration,
        containerId,
        onDismiss,
      }),
    [createToast]
  );

  const clear = useCallback(() => {
    setToasts({});
  }, [setToasts]);

  const toaster = useMemo(() => {
    return {
      info,
      success,
      error,
      basic,
      clear,
    };
  }, [info, success, error, basic, clear]);

  return {
    toasts,
    dismissById,
    toaster,
    hasToasts: !!Object.keys(toasts).length,
  };
};

export default useToaster;
