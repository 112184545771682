import PropTypes from "prop-types";
import { DateTime, Interval } from "luxon";

import FieldControl from "./FieldControl";
import Label from "./Label";
import { required } from "common/validation";
import { FieldError, FieldContainer } from "../Form/styles";
import { DATETYPES } from "./constants";

const Field = (props) => {
  const {
    id,
    type,
    variant = "large",
    labelText,
    labelHint,
    labelPosition = "top",
    placeholder = "",
    isDisabled = false,
    isReadOnly = false,
    isRequired = false,
    showSkeleton = false,
    value = "",
    error = null,
    onChange,
    setIsFormDisabled,
    validator = props.isRequired ? required() : null,
    ...rest
  } = props;

  const validateAndPropagateFieldChange = (valueOrEvent) => {
    const newValue = valueOrEvent?.target
      ? valueOrEvent.target.value
      : valueOrEvent;

    if (validator) {
      try {
        const errorMessage = validator(newValue) || "";
        onChange(newValue, errorMessage);
      } catch (error) {
        console.error(error);
        onChange(newValue, error.message || error?.toString() || "");
      }
    } else {
      onChange(newValue, "");
    }
  };

  // Will not fire onChange from parent form unless the new value is valid
  const validateBeforePropagateFieldChange = (valueOrEvent) => {
    const newValue = valueOrEvent?.target
      ? valueOrEvent.target.value
      : valueOrEvent;

    if (validator) {
      try {
        const errorMessage = validator(newValue) || "";

        if (errorMessage) {
          onChange(value, errorMessage);
          return errorMessage;
        }

        onChange(newValue);
      } catch (error) {
        console.error(error);
        onChange(newValue, error.message || error?.toString() || "");
      }
    } else {
      onChange(newValue, "");
    }
  };

  return (
    <FieldContainer>
      <Label
        htmlFor={id}
        text={labelText}
        hint={labelHint}
        position={labelPosition}
        variant={variant}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
      >
        <FieldControl
          type={type}
          id={id}
          placeholder={placeholder}
          variant={variant}
          isDisabled={showSkeleton || isDisabled}
          isReadOnly={isReadOnly}
          showSkeleton={showSkeleton}
          value={value}
          onChange={validateAndPropagateFieldChange}
          validateBeforeChange={validateBeforePropagateFieldChange}
          setIsFormDisabled={setIsFormDisabled}
          error={error}
          {...rest}
        />
      </Label>
      {variant !== "small" && <FieldError>{error}</FieldError>}
    </FieldContainer>
  );
};

Field.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "text",
    "password",
    "email",
    "number",
    "textarea",
    "combobox",
    "toggle",
    "checkbox",
    "radio",
    "slider",
    "date",
    "search",
    "query",
  ]).isRequired,
  checkboxType: PropTypes.oneOf(["basic", "explicit"]),
  labelText: PropTypes.string,
  labelHint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelPosition: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(["small", "large"]),
  showSkeleton: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.instanceOf(DateTime),
    PropTypes.instanceOf(Interval),
  ]).isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validator: PropTypes.func,
  isMultiValue: PropTypes.bool,
  options: PropTypes.array,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,

  // DatePicker props
  dateType: PropTypes.oneOf(DATETYPES),
  isRange: PropTypes.bool,
  minDate: PropTypes.instanceOf(DateTime),
  maxDate: PropTypes.instanceOf(DateTime),
  excludeDates: PropTypes.arrayOf(PropTypes.instanceOf(DateTime)),
  excludeDateIntervals: PropTypes.arrayOf(PropTypes.instanceOf(Interval)),
};

export default Field;
