import { useEffect, useMemo, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { animated, useSpring } from "react-spring";
import { ErrorBoundary } from "@datadog/browser-rum-react";

import Loader from "core/components/Loader";

import Pagination from "./Pagination";
import ServerSidePaginator from "./ServerSidePaginator";
import HeaderWithTooltip from "./HeaderWithTooltip";
import RowErrorFallback from "./RowErrorFallback";
import { Table, THead, TBody, Tr, Th, Td } from "./styles";

export { ServerSidePaginator, HeaderWithTooltip };
export { TextWithIconTableCell, SearchableTableCell } from "./Cell";

const TBodyWithState = ({ isLoading, isRefreshing, hasNoData, children }) => {
  if (isLoading || (hasNoData && isRefreshing)) {
    return (
      <TBody data-table-body data-table-loading={isLoading}>
        <div
          style={{
            margin: "15vh",
            textAlign: "center",
          }}
        >
          <Loader />
        </div>
      </TBody>
    );
  }

  if (hasNoData) {
    return (
      <TBody data-table-body data-table-loading={isRefreshing}>
        <div
          style={{
            margin: "15vh",
            textAlign: "center",
          }}
        >
          {isRefreshing ? "Loading..." : "No records found"}
        </div>
      </TBody>
    );
  }

  return (
    <TBody data-table-body data-table-loading={isLoading}>
      {children}
    </TBody>
  );
};

const AnimatedRow = (props) => {
  const { row, index, selectedRowIds = [] } = props;
  const [isUpdated, setIsUpdated] = useState(row.original.isUpdated || false);
  const [updateDuration, setUpdateDuration] = useState(750);

  const baseBgColor = useMemo(
    () => ((index + 2) % 2 === 0 ? "#f3f2f2" : "#fff"),
    [index]
  );
  const selectedBgColor = "#c7d8f0";
  const isSelected = selectedRowIds.includes(row.id);
  const originalBgColor = useMemo(
    () => (isSelected ? selectedBgColor : baseBgColor),
    [isSelected, baseBgColor]
  );
  const updateFlashBgColor = selectedBgColor;

  const [styles] = useSpring(
    () => ({
      from: { backgroundColor: originalBgColor },
      to: {
        backgroundColor: isUpdated ? updateFlashBgColor : originalBgColor,
      },
      config: { duration: updateDuration },
    }),
    [isUpdated, isSelected, updateDuration, originalBgColor]
  );

  useEffect(() => {
    if (isUpdated) {
      setTimeout(() => {
        setIsUpdated(false);
        setTimeout(() => {
          setUpdateDuration(0);
        }, 750);
      }, 500);
    }
  }, [isUpdated]);

  return (
    <Tr as={animated.div} style={styles} data-cy="tableRow">
      {row.getVisibleCells().map((cell) => {
        return (
          <Td
            key={cell.id}
            style={{
              width: cell.column.getSize(),
              ...cell.column.columnDef.customStyles,
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
    </Tr>
  );
};

const ReactTable = (props) => {
  const {
    data,
    columns,
    getRowId,
    defaultState = {},
    isRefreshing,
    searchConfig = { columns: [], searchValue: "" },
    isLoading = false,
    selectedRowIds = [],
    multiSortEnabled = true,
    serverSidePagination = false,
    PaginationComponent,
    serverSideSorting = false,
    handleSortChange,
    sorting,
    columnVisibility,
    pageKey,
  } = props;

  const columnsById = useMemo(
    () =>
      columns?.reduce(
        (obj, col) => ({ ...obj, [col.id || col.accessorKey]: col }),
        {}
      ) || {},
    [columns]
  );

  const searchFunction = useMemo(
    () => () =>
      data.filter((row) => {
        // Build a string based on the values of all the columns
        const compositeString = searchConfig.columns
          .filter((column) => columnsById[column])
          .map(
            (column) =>
              columnsById[column].accessorKey
                ? row[columnsById[column].accessorKey] // use string based key
                : columnsById[column].accessorFn(row) // or use function to determine key
          )
          .join(" ");

        // Rough check any potential match to keep row in data
        return compositeString
          .toLowerCase()
          .includes(searchConfig.searchValue.toLowerCase());
      }),
    [columnsById, data, searchConfig.columns, searchConfig.searchValue]
  );

  const filteredData = useMemo(
    () => (searchConfig ? searchFunction() : data),
    [data, searchConfig, searchFunction]
  );

  const sortIcons = {
    asc: faArrowUp,
    desc: faArrowDown,
  };

  const [pagination, setPagination] = useState(
    defaultState?.pagination || {
      pageIndex: 0,
      pageSize: 20,
    }
  );

  useEffect(() => {
    setPagination(
      defaultState?.pagination || {
        pageIndex: 0,
        pageSize: 20,
      }
    );
  }, [defaultState?.pagination, searchConfig?.searchValue]);

  const reactTable = useReactTable({
    data: filteredData,
    columns,
    getRowId,
    initialState: {
      sorting: [{ id: "name", desc: false }],
      ...defaultState,
    },
    state: {
      ...(sorting ? { sorting } : {}),
      columnVisibility,
      pagination,
    },
    onPaginationChange: setPagination,
    meta: {
      searchConfig,
    },
    enableSorting: true,
    enableMultiSort: multiSortEnabled,
    manualPagination: serverSidePagination,
    autoResetPageIndex: false,
    manualSorting: serverSideSorting,
    enableSortingRemoval: false,
    ...(handleSortChange ? { onSortingChange: handleSortChange } : {}),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const tableRows = reactTable.getRowModel().rows;

  return (
    <div>
      <Table>
        <THead style={{ userSelect: "none" }}>
          {reactTable.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const sorted = header.column.getIsSorted();
                const tooltip = header.column.columnDef.tooltip;
                const hasTooltip = !!tooltip;

                const headerContent = header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    );

                return (
                  <Th
                    key={header.id}
                    style={{
                      position: "relative",
                      width: header.getSize(),
                      cursor: header.column.getCanSort() ? "pointer" : "text",
                      ...header.column.columnDef.customStyles,
                    }}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <span
                      id={header.id}
                      style={{ width: "100%", height: "auto" }}
                    >
                      {headerContent}
                    </span>

                    {hasTooltip && (
                      <Tooltip
                        anchorId={header.id}
                        html={tooltip}
                        positionStrategy="fixed"
                      />
                    )}
                    {sorted ? (
                      <div
                        style={{
                          position: "absolute",
                          right: 0,
                          backgroundColor: "#fafaf9",
                        }}
                      >
                        {" "}
                        <FontAwesomeIcon icon={sortIcons[sorted]} />
                      </div>
                    ) : null}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </THead>
        <TBodyWithState
          isLoading={isLoading}
          isRefreshing={isRefreshing}
          hasNoData={tableRows.length === 0}
        >
          {tableRows.map((row, rowIndex) => {
            return (
              <ErrorBoundary
                key={row.id}
                onError={console.error}
                fallback={RowErrorFallback}
              >
                <AnimatedRow
                  key={row.id}
                  index={rowIndex}
                  selectedRowIds={selectedRowIds}
                  row={row}
                />
              </ErrorBoundary>
            );
          })}
        </TBodyWithState>
      </Table>
      {PaginationComponent ? (
        PaginationComponent
      ) : (
        <Pagination
          tableInstance={reactTable}
          isRefreshing={isRefreshing}
          pageKey={pageKey}
        />
      )}
    </div>
  );
};

export default ReactTable;
