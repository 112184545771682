import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospitals, faUserDoctor } from "@fortawesome/pro-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-regular-svg-icons";

import Table, {
  SearchableTableCell,
  ServerSidePaginator,
  HeaderWithTooltip,
} from "core/components/Table";
import { IconTableCell } from "core/components/Table/Cell";
import { Row } from "core/styles";
import { useMemo } from "react";
import Search from "core/components/Search";
import Filter from "core/components/Table/Filter";
import Popover from "core/components/Popover.js";
import { faEllipsis } from "@fortawesome/pro-light-svg-icons";

const CheckBox = ({ checked }) => {
  return (
    <FontAwesomeIcon
      icon={faCheckSquare}
      size="lg"
      style={{
        "--fa-primary-color": "#fff",
        "--fa-secondary-color": "#036dcc",
        "--fa-secondary-opacity": 1,
        visibility: checked ? "visible" : "hidden",
      }}
    />
  );
};

const tableSearchStyles = {
  container: {
    width: "100%",
    height: "auto",
  },
  input: {
    height: "24px",
    paddingRight: "25px",
    paddingBottom: "2px",
  },
  openButton: {
    backgroundPosition:
      "calc(100% - 12px) calc(1em + -4px), calc(100% - 7px) calc(1em + -4px),calc(100% - 2.5rem) 1.5em",
  },
  icon: {
    right: "8px",
    top: "7px",
    width: "10px",
    height: "10px",
  },
};

const baseTableSearchProps = {
  placeholder: " ",
  styles: tableSearchStyles,
};

const columnHelper = createColumnHelper();

const RelationshipsTable = (props) => {
  const {
    data,
    isRefreshing,
    isLoading,
    selectedRowIds,
    toggleSelectAllRows,
    toggleSelectSingleRow,
    pagination,
    changePage,
    sorting,
    handleSortChange,
    filter,
    updateFilter,
    queryKey,
    canUpdate,
  } = props;
  const navigate = useNavigate();
  const { orgId: organizationId } = useParams();

  const columns = useMemo(() => {
    return [
      columnHelper.group({
        id: "checkbox",
        size: 40,
        customStyles: {
          minWidth: "40px",
        },
        header: (props) => {
          return (
            <input
              type="checkbox"
              onChange={toggleSelectAllRows}
              checked={
                selectedRowIds.length && data.length === selectedRowIds.length
              }
            />
          );
        },
        columns: [
          columnHelper.display({
            id: "checkbox",
            size: 40,
            customStyles: {
              minWidth: "40px",
              textOverflow: "inherit",
            },
            header: "",
            cell: (props) => {
              return (
                <input
                  type="checkbox"
                  onChange={() =>
                    toggleSelectSingleRow(props.row.original.provider_npi)
                  }
                  checked={selectedRowIds.includes(
                    props.row.original.provider_npi
                  )}
                />
              );
            },
          }),
        ],
      }),
      columnHelper.group({
        header: "Type",
        size: 50,
        customStyles: {
          minWidth: "50px",
        },
        columns: [
          columnHelper.accessor("npi_type", {
            id: "npi_type",
            size: 50,
            customStyles: {
              minWidth: "50px",
            },
            enableResizing: false,
            enableSorting: false,
            header: "",
            cell: (props) => {
              const isPractice = props.getValue() === 2;
              return (
                <IconTableCell
                  icon={isPractice ? faHospitals : faUserDoctor}
                  iconAlt={isPractice ? "Practice" : "Provider"}
                  primaryColor="#712A6F"
                />
              );
            },
          }),
        ],
      }),
      columnHelper.accessor("provider_npi", {
        id: "provider_npi",
        header: "NPI",
        size: 150,
        customStyles: {
          minWidth: "125px",
        },
        enableSorting: filter.name !== "provider_npi",
        columns: [
          columnHelper.display({
            id: "search_provider_npi",
            accessorKey: "provider_npi",
            size: 150,
            customStyles: {
              minWidth: "125px",
            },
            header: (props) => {
              return (
                <Search
                  name="provider_npi"
                  value={filter.name === "provider_npi" ? filter.value : ""}
                  autoFocus={filter.name === "provider_npi"}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    const trimmedValue = value.trim();

                    if (!trimmedValue) {
                      updateFilter({ name: null, value: "" });
                    } else {
                      updateFilter({ name, value });
                    }
                  }}
                  {...baseTableSearchProps}
                />
              );
            },
            cell: SearchableTableCell,
            enableSorting: false,
          }),
        ],
      }),
      columnHelper.group({
        header: "Name",
        size: 250,
        customStyles: {
          minWidth: "225px",
        },
        columns: [
          columnHelper.accessor("name", {
            id: "name",
            header: "",
            size: 250,
            customStyles: {
              minWidth: "225px",
            },
            cell: SearchableTableCell,
            enableSorting: false,
          }),
        ],
      }),
      columnHelper.accessor("is_provider_in_network", {
        id: "is_provider_in_network",
        header: "Network Status",
        size: 175,
        customStyles: {
          minWidth: "150px",
        },
        enableSorting: filter.name !== "is_provider_in_network",
        // Matt: I have no idea why but this column seems to always default to descending on first click
        sortDescFirst: false,
        columns: [
          columnHelper.display({
            id: "search_is_provider_in_network",
            accessorKey: "is_provider_in_network",
            size: 175,
            customStyles: {
              minWidth: "150px",
            },
            header: () => {
              return (
                <Filter
                  name="is_provider_in_network"
                  autoFocus={filter.name === "is_provider_in_network"}
                  value={
                    filter.name === "is_provider_in_network"
                      ? filter.value
                      : "All"
                  }
                  onChange={(value) => {
                    if (value === "All") {
                      updateFilter({ name: null, value: "" });
                    } else {
                      updateFilter({ name: "is_provider_in_network", value });
                    }
                  }}
                  options={[
                    {
                      value: "All",
                      label: "All",
                    },
                    {
                      value: true,
                      label: "In Network",
                    },
                    {
                      value: false,
                      label: "Out of Network",
                    },
                  ]}
                />
              );
            },
            cell: (props) => {
              const inNetwork = props.getValue();
              return <div>{inNetwork ? "In Network" : "Out of Network"}</div>;
            },
            enableSorting: false,
          }),
        ],
      }),
      columnHelper.accessor("provider_affiliation", {
        id: "provider_affiliation",
        header: "Affiliation",
        size: 175,
        customStyles: {
          minWidth: "150px",
        },
        enableSorting: filter.name !== "provider_affiliation",
        columns: [
          columnHelper.display({
            id: "search_provider_affiliation",
            accessorKey: "provider_affiliation",
            size: 175,
            customStyles: {
              minWidth: "150px",
            },
            header: () => {
              return (
                <Filter
                  name="provider_affiliation"
                  autoFocus={filter.name === "provider_affiliation"}
                  value={
                    filter.name === "provider_affiliation"
                      ? filter.value
                      : "All"
                  }
                  onChange={(value) => {
                    if (value === "All") {
                      updateFilter({ name: null, value: "" });
                    } else {
                      updateFilter({ name: "provider_affiliation", value });
                    }
                  }}
                  options={[
                    {
                      value: "All",
                      label: "All",
                    },
                    {
                      value: "Employed",
                      label: "Employed",
                    },
                    {
                      value: "Aligned",
                      label: "Aligned",
                    },
                    {
                      value: "Joint Venture",
                      label: "Joint Venture",
                    },
                    {
                      value: "Affiliated",
                      label: "Affiliated",
                    },
                    {
                      value: "Owned",
                      label: "Owned",
                    },
                    {
                      value: "Partnership",
                      label: "Partnership",
                    },
                    {
                      value: "Unaffiliated",
                      label: "Unaffiliated",
                    },
                  ]}
                />
              );
            },
            cell: SearchableTableCell,
            enableSorting: false,
          }),
        ],
      }),
      columnHelper.accessor((row) => row, {
        id: "provider_participations",
        size: 500,
        customStyles: {
          minWidth: "500px",
        },
        enableSorting: false,
        header: "Provider Participations",
        columns: [
          columnHelper.accessor((row) => row, {
            id: "provider_participations",
            minSize: 500,
            enableSorting: false,
            header: (
              <Row style={{ gap: "12px", cursor: "default" }}>
                <HeaderWithTooltip info="Provider participates in IPA - <br />Independent Practice Association">
                  IPA
                </HeaderWithTooltip>
                <HeaderWithTooltip info="Provider participates in CIN - <br />Clinically Integrated Network">
                  CIN
                </HeaderWithTooltip>
                <HeaderWithTooltip info="Provider participates in PHO - <br />Physician Hospital Organization">
                  PHO
                </HeaderWithTooltip>
                <HeaderWithTooltip info="Provider participates in ACO - <br />Accountable Care Organization">
                  ACO
                </HeaderWithTooltip>
                <HeaderWithTooltip info="Provider participates in MCO - <br />Managed Care Organizations">
                  MCO
                </HeaderWithTooltip>
                <HeaderWithTooltip info="Provider participates in MSO - <br />Management Services Organizations">
                  MSO
                </HeaderWithTooltip>
                <HeaderWithTooltip info="Provider participates in ISM - <br />Integrated Salary Models">
                  ISM
                </HeaderWithTooltip>
              </Row>
            ),
            cell: (props) => {
              const row = props.getValue();
              return (
                <Row style={{ gap: "37px" }}>
                  <CheckBox checked={row.is_in_program_ipa} />
                  <CheckBox checked={row.is_in_program_cin} />
                  <CheckBox checked={row.is_in_program_pho} />
                  <CheckBox checked={row.is_in_program_aco} />
                  <CheckBox checked={row.is_in_program_mco} />
                  <CheckBox checked={row.is_in_program_mso} />
                  <CheckBox checked={row.is_in_program_ism} />
                </Row>
              );
            },
          }),
        ],
      }),
      columnHelper.display({
        id: "actions",
        size: 65,
        customStyles: {
          minWidth: "65px",
          justifyContent: "flex-end",
        },
        cell: ({ row }) => {
          const openRowMenuId = row.original.provider_npi;

          return (
            <Popover
              placement="bottom-end"
              offset={3}
              actions={[
                {
                  name: "Edit",
                  onClick: () => {
                    const {
                      provider_npi: npi,
                      npi_type,
                      name,
                      ...params
                    } = data.find((r) => r.provider_npi === openRowMenuId);

                    const updateUrl = `/config/${organizationId}/managed-providers/relationships/update/${npi}`;

                    navigate(`${updateUrl}?${createSearchParams(params)}`, {
                      state: { queryKey },
                    });
                  },
                },
                {
                  name: "Remove",
                  onClick: () => {
                    const removeUrl = `/config/${organizationId}/managed-providers/relationships/remove/${openRowMenuId}`;

                    navigate(removeUrl, { state: { queryKey } });
                  },
                },
              ]}
              TriggerComponent={(props) => {
                const { setRef, ...triggerProps } = props;

                return (
                  <div
                    style={{
                      width: "50px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faEllipsis}
                      style={{
                        width: "100%",
                        fontSize: "19px",
                        cursor: "pointer",
                      }}
                      ref={setRef}
                      {...triggerProps}
                    />
                  </div>
                );
              }}
            />
          );
        },
      }),
    ];
  }, [
    data,
    navigate,
    organizationId,
    queryKey,
    filter,
    selectedRowIds,
    toggleSelectAllRows,
    toggleSelectSingleRow,
    updateFilter,
  ]);

  const columnVisibility = useMemo(
    () => ({
      checkbox: canUpdate,
      actions: canUpdate,
    }),
    [canUpdate]
  );

  return (
    <>
      <Table
        data={data}
        columns={columns}
        isRefreshing={isRefreshing}
        isLoading={isLoading}
        defaultState={{ sorting }}
        sorting={sorting}
        serverSideSorting={true}
        handleSortChange={handleSortChange}
        getRowId={(row) => {
          return row.provider_npi;
        }}
        selectedRowIds={selectedRowIds}
        multiSortEnabled={false}
        serverSidePagination={true}
        columnVisibility={columnVisibility}
        PaginationComponent={
          <ServerSidePaginator
            prevPageToken={pagination.page_previous}
            nextPageToken={pagination.page_next}
            handlePreviousClick={() => changePage("PREVIOUS")}
            handleNextClick={() => changePage("NEXT")}
            isRefreshing={isRefreshing}
          />
        }
      />
    </>
  );
};

export default RelationshipsTable;
