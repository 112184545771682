import { useState, useMemo } from "react";
import { DateTime } from "luxon";
import { z } from "zod";

import useCurrentUser from "core/hooks/useCurrentUser";
import useToaster from "core/hooks/useToaster";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import { updatePassword } from "modules/auth/actions";
import Typography from "core/components/Typography";
import Error from "core/components/Error";
import Form from "core/components/Form/Form";
import Field from "core/components/Field";
import useForm from "core/hooks/useForm";
import {
  validate,
  required,
  password,
  doesNotMatchCurrentPassword,
  newAndConfirmPasswordsMatch,
  newPasswordZodValidator,
} from "common/validation";
import DetailsViewer from "../DetailsViewer";

const formSchema = z
  .object({
    oldPassword: z.string().min(1),
    newPassword: z.string().superRefine(newPasswordZodValidator),
    newPasswordConfirm: z.string(),
    usernameParts: z.array(z.string()),
  })
  .superRefine((data, context) => {
    if (data.newPassword === data.oldPassword) {
      context.addIssue({
        message: "Must be a new password.",
        path: ["newPassword"],
      });
    }

    const passwordContainsUsername = data.usernameParts.some((usernamePart) => {
      return data.newPassword.toLowerCase().includes(usernamePart);
    });
    if (passwordContainsUsername) {
      context.addIssue({
        message: "Must not contain your username in any form.",
        path: ["newPassword"],
      });
    }

    if (data.newPassword !== data.newPasswordConfirm) {
      context.addIssue({
        message: "Passwords must match.",
        path: ["newPasswordConfirm"],
      });
    }
  });

const defaultValues = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
};

export const ChangePasswordNavSection = ({
  user,
  passwordMeta,
  onMutationSuccess,
}) => {
  const { currentUser } = useCurrentUser();
  const { toaster } = useToaster();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState("");

  const usernameParts = useMemo(() => {
    const lowerEmail = currentUser?.email.split("@")[0].toLowerCase();

    if (lowerEmail) {
      const emailSplitIntoFourCharacters = lowerEmail.split("");

      return emailSplitIntoFourCharacters.reduce(
        (finalList, currentValue, currentIndex, originalArray) => {
          if (currentIndex > originalArray.length - 4) {
            return finalList;
          }

          return finalList.concat(
            originalArray.slice(currentIndex, currentIndex + 4).join("")
          );
        },
        []
      );
    }
  }, [currentUser?.email]);

  const apiUpdatePassword = useAuthenticatedMutation(updatePassword);

  const onSubmit = async (values) => {
    setError("");
    setIsLoading(true);

    try {
      await apiUpdatePassword(values);
      toaster.success("Password has been updated successfully");
      onMutationSuccess();
    } catch (error) {
      console.error(error);
      setError(error);
    }

    setIsLoading(false);
  };

  const { formValues, errors, isDisabled, onFormSubmit, onFieldChange } =
    useForm({
      placeholderValues: defaultValues,
      initialValues: defaultValues,
      disabledUntilTouched: true,
      onSubmit,
      formSchema,
      shouldValidateWithSchema: true,
      metadata: { usernameParts },
    });

  return (
    <>
      <Typography
        variant="p"
        noMargin
        style={{ lineHeight: "22px", marginBottom: "24px" }}
      >
        Passwords are valid for 60 days, at which point you will be asked to
        change your password. Password must be at least 15 characters in length.
      </Typography>

      <Form
        onSubmit={onFormSubmit}
        submitLabel={"Change Password"}
        disabled={isDisabled}
        isSubmitting={isLoading}
      >
        <Error>{error}</Error>
        <Field
          type="password"
          id="oldPassword"
          labelText="Current Password"
          isRequired={true}
          value={formValues.oldPassword}
          error={errors.oldPassword}
          onChange={onFieldChange("oldPassword")}
          validator={validate(required(), password())}
          autoComplete="off"
          autoFocus
        />
        <Field
          type="password"
          id="newPassword"
          labelText="New Password"
          isRequired={true}
          value={formValues.newPassword}
          error={errors.newPassword}
          onChange={onFieldChange("newPassword")}
          validator={validate(
            required(),
            password(),
            doesNotMatchCurrentPassword(formValues.oldPassword)
          )}
          autoComplete="new-password"
        />
        <Field
          type="password"
          id="newPasswordConfirm"
          labelText="Confirm New Password"
          isRequired={true}
          value={formValues.newPasswordConfirm}
          error={errors.newPasswordConfirm}
          onChange={onFieldChange("newPasswordConfirm")}
          validator={validate(
            required(),
            password(),
            newAndConfirmPasswordsMatch(formValues.newPassword)
          )}
          autoComplete="new-password"
        />
      </Form>

      <DetailsViewer
        style={{ paddingTop: 4 }}
        isLoading={false}
        data={[
          {
            label: "Last Changed",
            value:
              user.last_password_update_timestamp &&
              DateTime.fromMillis(user.last_password_update_timestamp).toFormat(
                "MMMM d, yyyy 'at' h:mm a"
              ),
          },
          ...passwordMeta,
        ]}
      />
    </>
  );
};
