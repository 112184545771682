import React, { useRef } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { Tooltip } from "react-tooltip";
import {
  FieldContainer,
  Textarea,
  LabelText,
  LabelRequired,
  LabelOptional,
  FieldControlSkeleton,
  InputErrorIcon,
} from "../styles";
import { TinyIcon } from "core/styles";
import Flex from "../../Flex";
import { faInfo } from "@fortawesome/pro-regular-svg-icons";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { required } from "common/validation";

const TextArea = (props) => {
  const {
    id,
    type = "textarea",
    variant = "large",
    labelText,
    labelHint,
    labelPosition = "top",
    placeholder = "",
    isDisabled = false,
    isReadOnly = false,
    isRequired = false,
    showSkeleton = false,
    value = "",
    error,
    onChange,
    onBlur,
    validateBeforeChange,
    validator = props.isRequired ? required() : null,
    ...rest
  } = props;

  const ref = useRef();

  const hintComponent = labelHint && (
    <>
      <TinyIcon
        icon={faInfo}
        data-tooltip-id={`${id}labelicon`}
        style={{ display: "inline" }}
      />
      <Tooltip
        id={`${id}labelicon`}
        html={typeof labelHint === "string" ? labelHint : null}
        content={typeof labelHint !== "string" ? labelHint : null}
        positionStrategy="fixed"
        place="right"
        style={{ zIndex: "1000" }}
      />
    </>
  );

  const textComponent = labelText && (
    <LabelText htmlFor={id} disabled={isDisabled} readOnly={isReadOnly}>
      {labelText}
      {isRequired ? (
        <LabelRequired>*</LabelRequired>
      ) : (
        <LabelOptional> (Optional)</LabelOptional>
      )}
      &nbsp;
      {labelPosition === "top" && hintComponent}
    </LabelText>
  );

  const validateAndPropagateFieldChange = (valueOrEvent) => {
    const newValue = valueOrEvent?.target
      ? valueOrEvent.target.value
      : valueOrEvent;

    if (validator) {
      try {
        const errorMessage = validator(newValue) || "";
        onChange(newValue, errorMessage);
      } catch (error) {
        console.error(error);
        onChange(newValue, error.message || error?.toString() || null);
      }
    } else {
      onChange(newValue, null);
    }
  };

  const validateBeforePropagateFieldChange = (valueOrEvent) => {
    const newValue = valueOrEvent?.target
      ? valueOrEvent.target.value
      : valueOrEvent;

    if (validator) {
      try {
        const errorMessage = validator(newValue) || "";

        if (errorMessage) {
          onChange(value, errorMessage);
          return errorMessage;
        }

        onChange(newValue);
      } catch (error) {
        console.error(error);
        onChange(newValue, error.message || error?.toString() || null);
      }
    } else {
      onChange(newValue, null);
    }
  };

  const setValue = (newValue) => {
    if (typeof newValue === "function") {
      onChange(newValue(value));
    } else {
      onChange(newValue);
    }
  };

  return (
    <FieldContainer>
      <Flex
        width="100%"
        gap="5px"
        alignItems={labelPosition === "left" ? "baseline" : null}
        direction={labelPosition === "left" ? "row" : "column"}
      >
        {textComponent}
        {labelPosition === "left" && labelHint && <div>{hintComponent}</div>}
        {showSkeleton ? (
          <FieldControlSkeleton type={type} size={variant}>
            <Skeleton />
          </FieldControlSkeleton>
        ) : (
          <div
            style={{ position: "relative", minWidth: "fit-content" }}
            disabled={isDisabled}
            readOnly={isReadOnly}
            hasError={!!error}
          >
            <Textarea
              {...rest}
              rows={variant !== "small" ? "10" : "5"}
              ref={ref}
              type="textarea"
              disabled={isDisabled}
              readOnly={isReadOnly}
              placeholder={placeholder}
              variant={variant}
              setValue={setValue}
              onChange={validateAndPropagateFieldChange}
              validateBeforeChange={validateBeforePropagateFieldChange}
              error={error}
              onBlur={validateAndPropagateFieldChange}
            />

            {error && (
              <InputErrorIcon icon={faCircleExclamation} variant={variant} />
            )}
          </div>
        )}
      </Flex>
    </FieldContainer>
  );
};

TextArea.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
    toString: PropTypes.func,
  }),
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelHint: PropTypes.any,
  labelPosition: PropTypes.string,
  labelText: PropTypes.any,
  onBlur: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showSkeleton: PropTypes.bool,
  type: PropTypes.string,
  validateBeforeChange: PropTypes.any,
  validator: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.string,
};

export default TextArea;
