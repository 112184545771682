import { createRoot } from "react-dom/client";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore, Log as OidcLog } from "oidc-client";
import { datadogRum } from "@datadog/browser-rum";
import { reactPlugin } from "@datadog/browser-rum-react";

import App from "./App";
import GlobalStyle from "./global-styles";
import UserProvider from "core/context/UserProvider";
import ToastProvider from "core/context/ToastProvider";
import FlashProvider from "core/context/FlashProvider";
import NavigationProvider from "core/context/NavigationProvider";
import ThemeProvider from "core/context/ThemeProvider";
import { Utils } from "common";

import "normalize.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-tooltip/dist/react-tooltip.css";
import FeatureProvider from "core/context/FeatureProvider";
import { ENVIRONMENTS } from "common/utilities";
import pkg from "../package.json";

const currentEnvironment = Utils.getEnv();

const oidcSettings = {
  authority: process.env.REACT_APP_OIDC_AUTH_URL,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  response_type: "code",
  scope: process.env.REACT_APP_OIDC_SCOPE,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL,
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true, // default: true
  // https://authts.github.io/oidc-client-ts/interfaces/UserManagerSettings.html#revokeTokensOnSignout
  revokeTokensOnSignout: true, // default: false
  onSigninCallback: async (user) => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  monitorSession: true,
};

// Matt: Useful for monitoring internal user manager events
if (currentEnvironment !== ENVIRONMENTS.PROD) {
  OidcLog.logger = console;
}

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: process.env.REACT_APP_DATADOG_SITE,
  service: process.env.REACT_APP_DATADOG_SERVICE,
  env: process.env.REACT_APP_DATADOG_ENV,
  version: pkg.version,
  sessionSampleRate: Number(process.env.REACT_APP_DATADOG_SAMPLE_RATE),
  sessionReplaySampleRate: Number(
    process.env.REACT_APP_DATADOG_REPLAY_SAMPLE_RATE
  ),
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  storeContextsAcrossPages: true,
  defaultPrivacyLevel: "mask-user-input",
  plugins: [reactPlugin({ router: true })],
});

const queryCache = new QueryCache({});
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      staleTime: 30 * 1000,
      cacheTime: Infinity,
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  },
});

window.queryClient = queryClient;
window.queryCache = queryCache;

// Good resources for understanding limitations of using localStorage here instead of an asyncPersister
// https://rxdb.info/articles/localstorage.html
// https://dexie.org/
const OFFLINE_CACHE_KEY = `populi:${currentEnvironment}:offline`;
const localStoragePersister = createSyncStoragePersister({
  key: OFFLINE_CACHE_KEY,
  storage: window.localStorage,
});

const persistOptions = {
  queryClient,
  persister: localStoragePersister,
  maxAge: Infinity,
};

// Experimental feature, locked to exact version to avoid minor/patch update regressions
// Resources:
// - https://tanstack.com/query/latest/docs/framework/react/plugins/broadcastQueryClient#usage
// - https://app.studyraid.com/en/read/11355/355117/cache-synchronization-across-tabs
const QUERY_BROADCAST_CHANNEL = `populi:${currentEnvironment}:query-broadcast`;
broadcastQueryClient({
  queryClient,
  broadcastChannel: QUERY_BROADCAST_CHANNEL,
});

const root = createRoot(document.getElementById("root"));

root.render(
  <AuthProvider {...oidcSettings}>
    <ThemeProvider>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={persistOptions}
      >
        <FeatureProvider>
          <UserProvider>
            <FlashProvider>
              <ToastProvider>
                <NavigationProvider>
                  <App />
                </NavigationProvider>
              </ToastProvider>
            </FlashProvider>
          </UserProvider>
        </FeatureProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </PersistQueryClientProvider>
      <GlobalStyle />
    </ThemeProvider>
  </AuthProvider>
);
