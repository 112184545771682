import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

import { Column, Grid, GridColumn, Row } from "core/styles";
import Button from "core/components/Button";
import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import { getOrganizations } from "modules/admin/actions";
import Search from "core/components/Search";
import Loader from "core/components/Loader";
import CloseButton from "core/components/CloseButton";
import Typography from "core/components/Typography";

const ListContainer = styled.div`
  border-top: solid 1px #dddbda;
  min-height: 280px;
  max-height: 280px;
  overflow-y: auto;
  background-color: #fff;

  & > div:nth-child(odd) {
    background-color: #fafaf9;
  }
`;

const ListItem = styled.div`
  display: flex;
  height: 40px;
  padding: 10px 15px;
  border-bottom: solid 1px #dddbda;
  justify-content: flex-start;
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-weight: normal;
  cursor: pointer;

  &:hover {
    background-color: #f5f2fc !important;
  }

  ${(props) =>
    props.isSelected &&
    `
    font-weight: bold;
    background-color: #c7d8f0 !important;

    &:hover {
      background-color: #c7d8f0 !important;
    }
  `}
`;

const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f2f2;
  height: 280px;
  font-family: "Inter";

  strong {
    font-size: 16px;
    line-height: 22px;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
`;

const EmptyList = () => {
  return (
    <EmptyListContainer>
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        style={{
          width: "38px",
          height: "38px",
          padding: "12px",
          marginBottom: "13px",
          backgroundColor: "#fff",
        }}
      />
      <strong style={{ marginBottom: "4px" }}>No results found</strong>
      <span>
        We couldn’t find the organization you’re
        <br /> looking for.
      </span>
    </EmptyListContainer>
  );
};

const SwitchOrganizations = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgId: organizationId } = useParams();

  const [searchValue, setSearchValue] = useState("");
  const [selectedOrgId, setSelectedOrgId] = useState(organizationId);
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const fetchQueryCall = useAuthenticatedCall(getOrganizations);
  const { data: organizations = [], isLoading } = useQuery({
    queryKey: ["organizations"],
    queryFn: fetchQueryCall,
  });

  const handleCancel = () => {
    navigate(location.state.fromPath);
  };

  const handleSubmit = () => {
    navigate(location.state.fromPath.replace(organizationId, selectedOrgId));
  };

  const filteredOrganizations = organizations.filter((org) => {
    return (
      org.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      org.id.toLowerCase().includes(searchValue.toLowerCase())
    );
  });

  return (
    <div style={{ position: "relative", padding: "30px 24px" }}>
      <Grid
        columns="500px"
        justify="center"
        lift="15vh"
        style={{ marginTop: "120px" }}
      >
        <GridColumn style={{ width: "100%" }}>
          <CloseButton />

          <Typography variant="h2">Switch Organizations</Typography>

          <Column alignItems="start" style={{ width: "100%" }}>
            <Search
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search by Organization"
              styles={{
                container: {
                  width: "100%",
                },
              }}
            />

            {isLoading && (
              <div
                style={{
                  height: "280px",
                  width: "100%",
                  backgroundColor: "#F3F2F2",
                }}
              >
                <Loader />
              </div>
            )}
            {filteredOrganizations.length ? (
              <ListContainer
                className="orgList"
                data-cy="orgList"
                style={{ width: "100%" }}
              >
                {filteredOrganizations.map((org) => {
                  return (
                    <ListItem
                      key={org.id}
                      isSelected={org.id === selectedOrgId}
                      onClick={() => {
                        setSelectedOrgId(org.id);
                      }}
                    >
                      <div
                        style={{
                          minWidth: "15px",
                          marginRight: "10px",
                        }}
                      >
                        {org.id === selectedOrgId && (
                          <FontAwesomeIcon
                            icon={faCheck}
                            style={{
                              color: "#007EAC",
                            }}
                          />
                        )}
                      </div>
                      <div>{org.name}</div>
                      <div
                        style={{
                          fontStyle: "italic",
                          transform: "scale(.8)",
                          marginLeft: "5px",
                          opacity: 0.7,
                        }}
                      >
                        {org.id}
                      </div>
                    </ListItem>
                  );
                })}
              </ListContainer>
            ) : (
              !isLoading && <EmptyList />
            )}
          </Column>

          <Row style={{ marginTop: "20px", justifyContent: "flex-end" }}>
            <Button
              type="button"
              onClick={handleCancel}
              variant="outlined"
              width="auto"
            >
              Cancel
            </Button>

            <Button
              as="button"
              type="submit"
              onClick={handleSubmit}
              disabled={selectedOrgId === organizationId}
              width="auto"
              data-cy="submit"
            >
              Switch Organization
            </Button>
          </Row>
        </GridColumn>
      </Grid>
    </div>
  );
};

export default SwitchOrganizations;
