import PropTypes from "prop-types";
import {
  QueryBuilder as ReactQueryBuilder,
  RuleGroup,
  useValueEditor,
} from "react-querybuilder";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faCircleNotch } from "@fortawesome/sharp-solid-svg-icons";
import "react-querybuilder/dist/query-builder.css";

import Combobox from "core/components/Combobox";
import { FieldError, InputGroup, Label } from "core/components/Form/styles";

import { TextEditorInput } from "./Inputs";
import DebugBox from "./DebugBox";
import { Wrapper, StyledRemoveButton, SecondaryButton } from "./styles";
import {
  isRangeOperator,
  isListOperator,
  isNullOperator,
  convertFiltersToQuery,
  convertQueryToFilters,
} from "./utilities";
import {
  rangeOperators,
  multiValueOperators,
  singleValueOperators,
  allOperators,
} from "./constants";
import { useMemo } from "react";

/**
 * NOT DOING NOW BUT LATER
 * - Error states  (unknown / specific to product catalog)
 * - Disabled states (use cases unknown)
 * - Possibly use import/export functions to convert filters/query back and forth
 * - See: https://react-querybuilder.js.org/docs/next/utils/import
 */

/**
 * BUILD ISSUES
 * react-querybuilder sourcemaps in css file causes warning
 * warnings will fail in github actions
 * copied import 'react-querybuilder/dist/query-builder.css' into wrapper
 * adding sourcemap generation disabling resolves this issue for start
 */

const CustomRemoveAction = (props) => {
  const { handleOnClick } = props;

  return (
    <StyledRemoveButton onClick={handleOnClick}>
      <FontAwesomeIcon icon={faXmark} />
    </StyledRemoveButton>
  );
};

const CustomValueEditor = (props) => {
  const {
    inputType,
    type: editorType,
    operator,
    field,
    fieldData,
    value,
    handleOnChange,
    listsAsArrays,
    parseNumbers,
    values: editorValues,
    rule,
  } = props;
  const { name, label, values: options } = fieldData;

  const { multiValueHandler, valueAsArray } = useValueEditor({
    handleOnChange,
    inputType,
    operator,
    value,
    type: editorType,
    listsAsArrays,
    parseNumbers,
    values: editorValues,
    skipHook: true,
  });

  if (isNullOperator(operator)) {
    return "";
  } else if (isRangeOperator(operator)) {
    if (inputType === "date") {
      return (
        <div
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          <TextEditorInput
            id={field}
            field={field}
            inputType={inputType}
            name={name}
            label={label}
            title={label}
            options={options}
            value={valueAsArray}
            handleOnChange={handleOnChange}
            isMultiSelect={false}
            isDateRange={true}
            dateType={fieldData.dateType}
          />
        </div>
      );
    } else {
      const editors = ["from", "to"].map((key, index) => {
        return (
          <div key={key} style={{ minWidth: "100px" }}>
            <TextEditorInput
              id={field}
              field={field}
              inputType={inputType}
              name={name}
              label={label}
              title={label}
              options={options}
              value={valueAsArray[index] ?? ""}
              handleOnChange={(newValue) => multiValueHandler(newValue, index)}
              isMultiSelect={false}
              isLoading={rule.isLoading}
            />
          </div>
        );
      });

      return (
        <div
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {editors}
        </div>
      );
    }
  } else {
    const isMultiSelect = isListOperator(operator);

    return (
      <div style={{ minWidth: "225px" }} data-cy="value-editor">
        <TextEditorInput
          id={field}
          field={field}
          inputType={inputType}
          name={name}
          label={label}
          title={label}
          options={options}
          value={isMultiSelect ? valueAsArray : value}
          handleOnChange={handleOnChange}
          isMultiSelect={isMultiSelect}
          isDateRange={false}
          dateType={fieldData.dateType}
          isLoading={rule.isLoading}
        />
      </div>
    );
  }
};

const CustomCombinatorSelector = (props) => {
  const { handleOnChange, options, value, level } = props;

  if (level === 0) {
    return (
      <div style={{ width: "68px" }} data-cy="combinator-selector">
        <Combobox
          id="combinatorSelect"
          name="combinatorSelect"
          size="small"
          isLoading={options[0].isLoading}
          options={options.filter((option) => option.value === "and")}
          value={value}
          onChange={handleOnChange}
        />
      </div>
    );
  }

  return level > 0 ? (
    <div style={{ width: "68px" }}>
      <Combobox
        id="combinatorSelect"
        name="combinatorSelect"
        size="small"
        isLoading={options[0].isLoading}
        options={options}
        value={value}
        onChange={handleOnChange}
      />
    </div>
  ) : null;
};

const CustomAddRule = (props) => {
  const { handleOnClick, rules } = props;
  return (
    <SecondaryButton tabIndex="0" onClick={handleOnClick}>
      {rules[0]?.isLoading ? (
        <FontAwesomeIcon icon={faCircleNotch} spin />
      ) : (
        "Add Rule"
      )}
    </SecondaryButton>
  );
};

const CustomAddGroup = (props) => {
  const { handleOnClick, rules } = props;

  return (
    <SecondaryButton tabIndex="0" onClick={handleOnClick}>
      {rules[0]?.isLoading ? (
        <FontAwesomeIcon icon={faCircleNotch} spin />
      ) : (
        "Add Group"
      )}
    </SecondaryButton>
  );
};

const StyledRuleGroup = styled(RuleGroup)`
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
`;

const groupFields = (fields) => {
  const groupedFieldsMap = fields.reduce((groupedFields, field) => {
    return {
      ...groupedFields,
      [field.group]: groupedFields[field.group]
        ? {
            ...groupedFields[field.group],
            children: groupedFields[field.group].children.concat(field),
          }
        : {
            groupId: field.group,
            label: field.group,
            children: [field],
          },
    };
  }, {});

  return Object.values(groupedFieldsMap);
};

const CustomFieldSelector = (props) => {
  const { handleOnChange, options, value, rule } = props;

  const preparedOptions = useMemo(() => {
    if (options[0].group) {
      return groupFields(options);
    } else {
      return options;
    }
  }, [options]);

  return (
    <div style={{ minWidth: "138px" }} data-cy="field-selector">
      <Combobox
        id="fieldSelect"
        name="fieldSelect"
        size="small"
        isLoading={rule.isLoading}
        isSearchable={true}
        options={preparedOptions}
        value={value}
        onChange={handleOnChange}
        dataCy="field-selector-combobox"
      />
    </div>
  );
};

const CustomOperatorSelector = (props) => {
  const { handleOnChange, options, value, rule } = props;

  return (
    <div style={{ minWidth: "126px" }} data-cy="operator-selector">
      <Combobox
        id="operatorSelect"
        name="operatorSelect"
        size="small"
        isLoading={rule.isLoading}
        options={options}
        value={value}
        onChange={handleOnChange}
        dataCy="operator-selector-combobox"
      />
    </div>
  );
};

const combinatorOptions = [
  { label: "And", name: "and", value: "and" },
  { label: "Or", name: "or", value: "or" },
];

const QueryBuilder = ({
  label,
  filters = [],
  fields = [],
  onChange = null,
  showDebug = false,
  isLoading = false,
  errorMessage = "",
  dataCy,
}) => {
  const handleQueryChange = (newQuery) => {
    const newFilters = convertQueryToFilters(newQuery);
    onChange(newFilters);
  };
  const query = convertFiltersToQuery(filters, isLoading);

  return (
    <div data-cy="query-builder">
      <Wrapper>
        <InputGroup data-cy={dataCy}>
          {label && <Label>{label}</Label>}
          <ReactQueryBuilder
            query={query}
            onQueryChange={handleQueryChange}
            fields={fields}
            operators={allOperators}
            combinators={combinatorOptions.map((o) => ({ ...o, isLoading }))}
            listsAsArrays={true}
            parseNumbers={true}
            resetOnOperatorChange={true}
            enableMountQueryChange={false}
            controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
            controlElements={{
              addRuleAction: CustomAddRule,
              addGroupAction: CustomAddGroup,
              removeGroupAction: CustomRemoveAction,
              removeRuleAction: CustomRemoveAction,
              ruleGroup: StyledRuleGroup,
              combinatorSelector: CustomCombinatorSelector,
              operatorSelector: CustomOperatorSelector,
              fieldSelector: CustomFieldSelector,
              valueEditor: CustomValueEditor,
            }}
          />
          <FieldError>{errorMessage}</FieldError>
        </InputGroup>
      </Wrapper>
      <DebugBox isVisible={showDebug} query={query} filters={filters} />
    </div>
  );
};

QueryBuilder.propTypes = {
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showDebug: PropTypes.bool,
  errorMessage: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      inputType: PropTypes.oneOf(["text", "number", "date", "select", "zip"])
        .isRequired,
      valueEditorType: PropTypes.oneOf(["multiselect", "date"]),
      dateType: PropTypes.oneOf(["date", "month", "quarter", "year"]),
      // Matt: Will enhance later
      operators: PropTypes.array.isRequired,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  filters: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // SingleValueFilter
      PropTypes.shape({
        operator: PropTypes.oneOf(singleValueOperators.map((o) => o.value))
          .isRequired,
        field: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
      // MultiValueFilter
      PropTypes.shape({
        operator: PropTypes.oneOf(multiValueOperators.map((o) => o.value))
          .isRequired,
        field: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          ).isRequired,
        ]),
      }),
      // RangeFilter
      PropTypes.shape({
        operator: PropTypes.oneOf(rangeOperators.map((o) => o.value))
          .isRequired,
        field: PropTypes.string.isRequired,
        from: PropTypes.any,
        to: PropTypes.any,
      }),
      // ModelFilter
      // Matt: I believe this might be getting different support, need to double check

      // ...

      // NestedFilter
      PropTypes.shape({
        operator: PropTypes.string,
        filters: PropTypes.array.isRequired,
      }),
    ])
  ),
};

export default QueryBuilder;
