import { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, matchPath, useParams } from "react-router-dom";
import { ErrorBoundary } from "@datadog/browser-rum-react";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { DateTime } from "luxon";
import { datadogRum } from "@datadog/browser-rum";

import PageNotFound from "pages/404";
import useCurrentUser from "core/hooks/useCurrentUser";
import useHasPermissions from "core/hooks/useHasPermissions";
import useToaster from "core/hooks/useToaster";
import Loader from "core/components/Loader";
import ProtectedLayout from "core/components/ProtectedLayout";
import { DURATION_30_MIN } from "common/utilities";
import { PERMISSIONS, ROLES } from "core/constants";
import { logoutChannel } from "modules/auth/channels";

const protectedRoutes = [
  { to: "/" },
  { to: "/404" },
  { to: "/admin", permissions: "admin:read" },
  {
    to: "/admin/switch-org",
    permissions: "org-management:switch",
  },
  {
    to: "/admin/switch-org/:orgId",
    permissions: "org-management:switch",
  },
  {
    to: "/admin/org-management",
    permissions: "org-management:read",
  },
  {
    to: "/admin/org-management/create",
    permissions: "org-management:write",
  },
  {
    to: "/admin/org-management/:orgId",
    permissions: "user-management:read",
  },
  {
    to: "/admin/org-management/:organizationId/users/create",
    permissions: "user-management:write",
  },
  {
    to: "/admin/org-management/:organizationId/users/:email/profile/*",
    permissions: "user-management:write",
  },
  {
    to: "/admin/org-management/:orgId/update/*",
    permissions: "org-management:write",
  },
  {
    to: "/config/:orgId/managed-providers",
    permissions: "configurations:read",
  },
  {
    to: "/config/:orgId/managed-providers/aliases",
    permissions: "configurations:read",
  },
  {
    to: "/config/:orgId/managed-providers/relationships",
    permissions: "configurations:read",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/update",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/update/:npi",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/remove",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/remove/:npi",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/update",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/update/:npi",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/remove",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/remove/:npi",
    permissions: "configurations:write",
  },
];

const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
  const location = useLocation();
  const errorLocation = useRef(location.pathname);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.pathname, resetErrorBoundary]);

  return (
    <ProtectedLayout
      loggedInUser={currentUser}
      pageTitle="404"
      showBackLink={false}
      hideAppShell={false}
    >
      <PageNotFound hideGoBack={true} />
    </ProtectedLayout>
  );
};

const ProtectedRoute = ({
  pageTitle,
  backLinkText,
  backLinkTo,
  backLinkPermissions,
  hideAppShell = false,
  children,
}) => {
  const auth = useAuth();
  const location = useLocation();
  const params = useParams();
  const { toaster } = useToaster();
  const { currentUser, setCurrentUser } = useCurrentUser();
  const authStartedLoading = useRef(DateTime.now());
  const [isPageLoading, setIsPageLoading] = useState(auth.isLoading);

  // Mount channel subscription inside react app for all instances
  useEffect(() => {
    const handleLogoutMessage = (event) => {
      auth.removeUser(); // Clear local storage oidc values
      setCurrentUser(null); // trigger re-render while clearing context
    };
    logoutChannel.addEventListener("message", handleLogoutMessage);

    return () =>
      logoutChannel.removeEventListener("message", handleLogoutMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.error) {
      console.error(auth.error);

      if (auth.error.message === "No matching state found in storage") {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }
    }
  }, [auth.error]);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      logoutChannel.postMessage("logout");
      logoutChannel.close();
      auth.signinRedirect();
    }
  }, [auth]);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      auth.isAuthenticated &&
      !auth.isLoading &&
      (!currentUser || currentUser?.expiresAt !== auth.user.expires_at)
    ) {
      const now = Date.now();
      const passwordMeta = auth.user.profile.password_meta;
      const passwordLastUpdated = DateTime.fromMillis(passwordMeta.lastUpdated);
      const daysSincePasswordLastUpdated = DateTime.now()
        .diff(passwordLastUpdated, "days")
        .toObject().days;
      const daysRemaining =
        passwordMeta.expirationPeriod - daysSincePasswordLastUpdated;
      const isPasswordExpiring =
        daysRemaining <= passwordMeta.updateReminderPeriod;

      const loggedInUser = {
        email: auth.user.profile.email,
        name: auth.user.profile.name,
        organizationId: auth.user.profile.org_info?.id,
        orgName: auth.user.profile.org_info?.name,
        accessToken: auth.user.access_token,
        refreshToken: auth.user.refresh_token,
        role: ROLES[auth.user.profile.role] || "Org User",
        permissions: PERMISSIONS[ROLES[auth.user.profile.role]] || [],
        expirationTime: now + DURATION_30_MIN,
        expiresAt: auth.user.expires_at,
        lastActiveTime: now,
        passwordMeta,
        isPasswordExpiring,
      };

      setCurrentUser(loggedInUser);
      const loadTime = DateTime.now()
        .diff(authStartedLoading.current, "seconds")
        .toObject().seconds;

      setTimeout(() => {
        setIsPageLoading(false);
      }, 500 - loadTime);

      const identifiedUserSession = datadogRum.getUser();
      if (!identifiedUserSession?.email) {
        datadogRum.setUser({
          name: loggedInUser.name,
          email: loggedInUser.email,
          role: loggedInUser.role,
          organizationId: loggedInUser.organizationId,
        });
      }
    }
  }, [auth, currentUser, setCurrentUser]);

  useEffect(() => {
    if (location.state?.error) {
      toaster.error({ message: location.state.error });
      console.error("UNAUTHORIZED URL", location.pathname);
    } else {
      toaster.clear();
    }
  }, [location.state, location.pathname, toaster]);

  const matchedRoute = protectedRoutes.find((route) =>
    matchPath(route.to, location.pathname)
  );

  const userIsAuthorized = useHasPermissions(
    currentUser?.permissions,
    matchedRoute.permissions,
    { userEmail: currentUser?.email, paramEmail: params?.email }
  );

  const showBackLink = useHasPermissions(
    currentUser?.permissions,
    backLinkPermissions
  );

  if (auth.isLoading || !currentUser || isPageLoading) {
    return <Loader style={{ paddingBottom: "20vh" }} />;
  }

  if (!auth.isLoading && !auth.user) {
    return (
      <Navigate
        to="/error"
        state={{
          error:
            "Current user does not exist. Please contact support for assistance.",
        }}
        replace
      />
    );
  }

  if (!userIsAuthorized) {
    return (
      <Navigate
        to="/"
        state={{ error: "You do not have permission to view that page." }}
        replace
      />
    );
  }

  if (currentUser?.isPasswordExpiring) {
    return <Navigate to="/expiring-password" />;
  }

  return (
    <ErrorBoundary onError={console.error} fallback={ErrorFallbackPage}>
      <ProtectedLayout
        pageTitle={pageTitle}
        backLinkText={backLinkText}
        backLinkTo={backLinkTo}
        showBackLink={showBackLink}
        hideAppShell={hideAppShell}
      >
        {children}
      </ProtectedLayout>
    </ErrorBoundary>
  );
};

export default ProtectedRoute;
