import { useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { InputGroup, Label, StyledInput } from "../Form";
import { FieldError, InputSkeletonContainer } from "../Form/styles";

const NumberInput = (props) => {
  const {
    id,
    name,
    label,
    placeholder,
    isLoading,
    isDisabled,
    errorMessage,
    onChange,
    value,
    min,
    dataCy,
    max,
    width = "250px",
  } = props;
  const ref = useRef();
  const focus = () => ref.current.focus();

  const handleDecrement = () => {
    const newValue = value - 1;
    if (newValue < min) {
      onChange(Number(min));
    } else {
      onChange(newValue);
    }
  };

  const handleIncrement = () => {
    const newValue = value + 1;
    if (newValue > max) {
      onChange(Number(max));
    } else {
      onChange(newValue);
    }
  };

  const handleChange = (event) => {
    const newValue = Number(event.target.value.replaceAll(/\D/g, ""));

    onChange(newValue);
  };

  const handleBlur = () => {
    if (value < min) {
      onChange(min);
    } else if (value > max) {
      onChange(max);
    }
  };

  if (isLoading) {
    return (
      <InputGroup>
        <Label>{label}</Label>
        <InputSkeletonContainer>
          <Skeleton />
        </InputSkeletonContainer>
      </InputGroup>
    );
  }

  return (
    <InputGroup style={{ width: "fit-content" }}>
      <Label htmlFor={id}>{label}</Label>
      <div
        style={{
          position: "relative",
          width,
        }}
        onClick={focus}
      >
        <StyledInput
          ref={ref}
          id={id}
          name={name}
          placeholder={placeholder}
          disabled={isDisabled}
          value={value?.toLocaleString()}
          onChange={handleChange}
          onBlur={handleBlur}
          data-cy={`${dataCy}-input`}
        />
        <div
          style={{
            position: "absolute",
            display: "flex",
            right: "15px",
            gap: "15px",
            height: "41px",
            top: "0px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faMinus}
            style={{ cursor: "pointer" }}
            onClick={handleDecrement}
          />
          <FontAwesomeIcon
            icon={faPlus}
            style={{ cursor: "pointer" }}
            onClick={handleIncrement}
          />
        </div>
      </div>

      <FieldError>{errorMessage || ""}</FieldError>
    </InputGroup>
  );
};

export default NumberInput;
