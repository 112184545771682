import PropTypes from "prop-types";
import styled from "styled-components";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledChip = styled.div`
  font-family: Inter;
  text-align: left;
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  background-color: #eaeaec;
  color: #000000;
  user-select: none;
  display: flex;
  overflow-wrap: anywhere;
  align-items: center;

  ${(props) =>
    props.size === "small" &&
    `
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 1px;
      padding-bottom: 1px;
      gap: 2px;
  `}

  ${(props) =>
    props.size === "large" &&
    `
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 2px;
      padding-bottom: 2px;
      gap: 1px;
  `}

  ${(props) =>
    props.variant === "outlined" &&
    `
      border: 1px solid #979797;
      background-color: #ffffff;
      color: #702A6E;
      box-sizing: border-box;
      cursor: pointer;
    `}

${(props) =>
    props.variant === "actionable" &&
    props.size === "small" &&
    `
  `}

${(props) =>
    props.isDisabled &&
    `
      pointer-events: none;

      &:focus {
        outline: none;
      }
    `}

    ${(props) =>
    props.variant === "actionable" &&
    props.size !== "small" &&
    `
        padding-left: 7px;
        padding-bottom: 3px;
        padding-right: 4px;
      `}
`;

const ChipText = styled.div`
  height: fit-content;
  font-size: 12px;
  line-height: 14px;
  text-size-adjust: none;
  flex: 1 1 auto;
  padding-top: 1px;
`;

const ChipAction = styled.div`
  padding: 1px;
  cursor: pointer;
  color: #3e3e3c;
  line-height: 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  flex: 0 0 16px;

  ${(props) =>
    props.size === "small" &&
    `
      width: 14px;
      height: 14px;
      flex: 0 0 14px;
      margin-left: -1px;
  `}

  ${(props) =>
    props.size === "large" &&
    `
    width: 16px;
    height: 16px;
  `}

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    background-color: #c5c5c7;
  }
`;

const Chip = ({
  text = "",
  variant = "default",
  size = "large",
  onDismiss,
  tabIndex,
  isDisabled,
}) => {
  return (
    <StyledChip
      tabIndex={tabIndex}
      variant={variant}
      size={size}
      isDisabled={isDisabled}
      // Prevent event from bubbling up to parent components
      // i.e. Combobox (react-select)
      onMouseDown={(event) => event.stopPropagation()}
      onKeyDown={(event) => {
        if (
          ["Backspace", "Delete"].includes(event.key) &&
          variant === "actionable" &&
          onDismiss
        ) {
          onDismiss(event);
        }
      }}
    >
      {variant === "actionable" && onDismiss ? (
        <>
          <ChipText>{text}</ChipText>
          <ChipAction size={size}>
            <FontAwesomeIcon icon={faXmark} onClick={onDismiss} />
          </ChipAction>
        </>
      ) : (
        <ChipText>{text}</ChipText>
      )}
    </StyledChip>
  );
};

Chip.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(["default", "actionable"]),
  size: PropTypes.oneOf(["small", "large"]),
  onDismiss: PropTypes.func,
};

export default Chip;
