import styled from "styled-components";
import { Utils } from "common";

export const Table = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  min-height: 650px;
  width: 100%;
  border: solid 1px #dddbda;
  background-color: #ffffff;
`;

export const TableError = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  min-height: 650px;
  width: 100%;
  border: solid 1px #dddbda;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const THead = styled.div`
  background-color: #fafaf9;
`;

export const TBody = styled.div``;

const TCell = styled.div`
  font-family: Inter;
  font-size: 0.85rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

export const Td = styled(TCell)`
  padding: 5px 12px;
  color: #080707;
  overflow-x: clip;
  text-overflow: ellipsis;

  :first-child {
    padding-left: 20px;
  }

  :last-child {
    padding-right: 20px;
  }
`;

export const Tr = styled.div`
  min-height: 30px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #dddbda;

  &:has(${Td}):hover,
  &:hover ${Td} {
    background-color: #d7e3f4 !important;
  }
`;

export const TrError = styled.div`
  color: #ba351c;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: solid 1px #dddbda;
`;

export const Th = styled(TCell)`
  min-height: 32px;
  background-color: #fafaf9;
  color: #514f4d;
  padding: 2px 12px;
  display: flex;
  align-items: center;
  gap: 10px;

  :first-child {
    padding-left: 20px;
  }

  > div {
    margin-top: 2px;
  }

  ${(props) =>
    props.doubleHeader &&
    `
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    gap: 5px;
    padding: 0px;
  `}
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
  font-size: 0.9rem;
  color: #4a4a4a;
  font-family: "Inter";

  .total-pages {
    margin-left: 2px;
    color: #007eac;
    font-size: 1rem;
  }
`;

export const PaginationActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 36px;
  width: 36px;
  color: #4a4a4a;
  border: 0.5px solid #bbbbbb;
  background-color: #f9f9f9;
  cursor: pointer;

  :hover {
    background-color: ${Utils.newShade("#f9f9f9", -20)};
  }

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;

    svg {
      opacity: .5;
    }
  `}
`;

export const PreviousButton = styled(PaginationActionButton)`
  border-radius: 4px 0 0 4px;
`;

export const NextButton = styled(PaginationActionButton)`
  border-radius: 0px 4px 4px 0px;
`;

export const PageNumberInput = styled.input`
  box-sizing: border-box;
  height: 36px;
  width: 42px;
  margin-left: -10px;
  border: 1px solid #bbbbbb;
  background-color: #ffffff;
  box-shadow: inset 0 1px 3px 0 rgba(153, 153, 153, 0.5),
    0 1px 1px 0 rgba(221, 221, 221, 0.5);
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TextWithMark = styled.span`
  flex-shrink: 1;
  overflow-x: clip;
  text-overflow: ellipsis;

  mark {
    padding: 0px 1px;
    background-color: #ff7300;
    border-radius: 4px;
  }
`;
