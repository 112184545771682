import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import {
  faCircleCheck,
  faCircleInfo,
  faCircleX,
} from "@fortawesome/pro-regular-svg-icons";

import useToast from "core/hooks/useToaster";

// Adapting this https://css-tricks.com/timer-bars-in-css-with-custom-properties/
const timer = keyframes`
 to {
    transform: scaleX(0);
  }
`;

// Adapting this https://css-tricks.com/css3-progress-bars/
const loading = keyframes`
   0% {
     background-position: 0 0;
   }
   100% {
     background-position: 75px 75px;
     /* background-position: 50px 50px; */
   }
`;

const Toast = styled.div`
  --index: 0;
  --start: 1;

  ${(props) =>
    props.index >= 0 &&
    `
    --index: ${props.index};
    --start: ${props.index + 1};
  `}

  ${(props) =>
    props.position === "top-center" &&
    `
      top: calc(var(--index) * var(--toast-spacing));
      transform: scale(calc(var(--toast-base-scale) + (var(--index) * var(--toast-grow))));
      transition: top 200ms ease-in-out, transform 150ms linear;
    `}

  ${(props) =>
    props.position === "bottom-right" &&
    `
      bottom: calc((var(--toast-count) - var(--start)) * var(--toast-spacing));
      transform: scale(calc(var(--toast-base-scale) + (var(--index) * var(--toast-grow))));
      transition: bottom 200ms ease-in-out, transform 150ms linear;
  `}

  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: ${(props) => getToastBackground(props.type)};
  color: ${(props) => getToastColor(props.type)};
  padding: 12px 15px;
  width: 400px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  z-index: calc(var(--start) * 10000);
  user-select: none;
`;

const ToasterContainer = styled.div`
  ${(props) =>
    props.count &&
    `
    --toast-base-scale: ${1 - props.count * 0.03};
    --toast-grow: 0.03;
    --toast-count: ${props.count};
    height: ${props.count * 20}px;
  `}

  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10000;
  cursor: default;

  ${(props) =>
    props.position === "top-center" &&
    `
      --toast-spacing: -55px;
      justify-content: flex-start;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      transform-origin: bottom;

      :hover {
        --toast-spacing: 3px;
        --toast-base-scale: 1;
        --toast-grow: 0;
        height: auto;
      }
  `}

  ${(props) =>
    props.position === "bottom-right" &&
    `
      --toast-spacing: -55px;
      justify-content: flex-end;
      right: 35px;
      bottom: 25px;
      transform: translateX(0px);

      :hover {
        --toast-spacing: 3px;
        --toast-base-scale: 1;
        --toast-grow: 0;
        height: auto;
      }
  `}
`;

const getToastBackground = (type) => {
  switch (type) {
    case "success":
      return "#008844;";

    case "info":
      return "#007EAC;";

    case "error":
      return "#BA351C;";

    default:
      return "#F7F8F9;";
  }
};

const getToastColor = (type) => {
  switch (type) {
    case "success":
      return "#F7F8F9;";

    case "info":
      return "#F7F8F9;";

    case "error":
      return "#F7F8F9;";

    default:
      return "#031621;";
  }
};

const getToastIcon = (type) => {
  switch (type) {
    case "success":
      return faCircleCheck;

    case "info":
      return faCircleInfo;

    case "error":
      return faCircleX;

    default:
      return faCircleInfo;
  }
};

const ToastTitle = styled.div`
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
`;

const ToastMessage = styled.div`
  font-family: Inter;
  font-size: 14px;
`;

const getDismissColor = (type) => {
  switch (type) {
    case "success":
      return "#9fdebe";

    case "info":
      return "#97b9e7";

    case "error":
      return "#fba398";

    default:
      return "#B4B8BA";
  }
};

const ToastDismiss = styled.div`
  cursor: pointer;
  margin-left: auto;
  margin-right: 4px;
  margin-bottom: 2px;
  padding: 2px 4px;
  border-radius: 100%;

  :hover {
    background-color: ${(props) => getDismissColor(props.type)};
  }
`;

const getTimerColor = (type) => {
  switch (type) {
    case "success":
      return "#1AA366;";

    case "info":
      return "#00529a;";

    case "error":
      return "#CA2A29;";

    default:
      return "#a3a1ab;";
  }
};

const ToastTimer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 5px;
  width: 99%;
  border-bottom-left-radius: 4px;
  background-color: ${(props) => getTimerColor(props.type)};

  /* Animation */
  animation-name: ${timer};
  animation-duration: ${(props) => `calc(${props.duration}s)`};
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: unset;
  transform-origin: left center;
`;

const ToastLoadingBar = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 5px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${(props) => getTimerColor(props.type)};
  overflow: hidden;

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.5) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.5) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 50px 50px;
    animation: ${loading} 2s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
  }
`;

const Toaster = ({ containerId = "default", position = "top-center" }) => {
  const { toasts, dismissById } = useToast();

  return (
    <ToasterContainer count={Object.values(toasts).length} position={position}>
      {Object.values(toasts).length
        ? Object.values(toasts)
            .filter((t) => t.containerId === containerId)
            .map((toast, index) => {
              const { id, type, title, message, isInfinite, duration } = toast;

              return (
                <Toast
                  key={id}
                  type={type}
                  index={index}
                  data-index={index}
                  position={position}
                >
                  <FontAwesomeIcon icon={getToastIcon(type)} size="lg" />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <ToastTitle>{title}</ToastTitle>
                    <ToastMessage>{message}</ToastMessage>
                  </div>
                  <ToastDismiss
                    type={toast.type}
                    onClick={() => dismissById(id)}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </ToastDismiss>
                  {type === "info" && isInfinite ? (
                    <ToastLoadingBar type={type} />
                  ) : isInfinite ? null : (
                    <ToastTimer type={type} duration={duration / 1000} />
                  )}
                </Toast>
              );
            })
        : null}
    </ToasterContainer>
  );
};

export default Toaster;
